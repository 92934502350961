import React, {useCallback, useEffect, useRef, useState} from "react";
import Loader from "../components/Loader";
import SearchBar from "../components/SearchBar";
import {useSelector} from "react-redux";
import dbService from "../utils/DbService";
import Config from "../config";
import {Autocomplete, GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {useNavigate} from "react-router-dom";
import DettagliAttivita from "../components/DettagliAttivita";
import "../pagesStyle/Mappa.scoped.css";


const Mappa = () => {

    const [map, setMap] = useState(null);
    const [previewOpen, setPreviewOpen] = useState([]);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [locali, setLocali] = useState([]);
    const [localiFiltrati, setLocaliFiltrati] = useState([]);
    const [locale, setLocale] = useState({});
    const [loading, setLoading] = useState(true);
    const markersRef = useRef([]);

    const navigate = useNavigate();
    const concorsoId = useSelector(state => state.user.concorso.id);
    const token = useSelector(state => state.user.token);

    useEffect(() => {
        dbService.getLocali(concorsoId, token).then(resp => {
            setLocali(() => {
                setLoading(false);
                return resp;
            });
            setLocaliFiltrati(resp);
        });
    }, [concorsoId])


    const filtra = testo => {
        const tempLocali = [];
        locali.forEach(locale => {
            if (!testo || locale.nome_attivita.toLowerCase().indexOf(testo) !== -1) {
                tempLocali.push(locale);
            }
        })
        setLocaliFiltrati(() => tempLocali)
    }

    const mapCenter = useSelector(state => state.user.concorso.mappa) || Config.mapCenter;

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script', googleMapsApiKey: Config.googleMapsApiKey
    })

    const onLoad = useCallback(map => {
        const bounds = new window.google.maps.LatLngBounds(mapCenter);
        map.fitBounds(bounds);
        setMap(map);
        window.google.maps.event.addListener(map, 'tilesloaded', () => {
            const autoComplete = new Autocomplete({state: inputRicerca.current});
            autoComplete.addListener('place_changed', () => {
                const place = autoComplete.getPlace();
                if (place.geometry) {
                    map.panTo(place.geometry.location);
                }
            })
        })
    }, [mapCenter]);

    const onUnmount = useCallback(map => setMap(null), []);

    const inputRicerca = useRef(null);

    const openPreview = (key, locale) => {
        map.panTo({lat: parseFloat(locale.latitudine), lng: parseFloat(locale.longitudine)});
        setPreviewOpen(prev => {
            const temp = prev;
            temp[key] = true;
            return temp
        });
        setLocale(locale);
    };

    const closePreview = () => {
        setPreviewOpen(prev => {
            let temp = prev;
            temp = temp.map(el => false)
            return temp;
        });
        setLocale({});
    };

    return (<div>
        {loading && <Loader/>}
        <div className="navigation-container" style={{height: `${window.innerHeight - 50}px`}}>
            <SearchBar ricerca={filtra}/>
            <div className="hidden-bar">
                <input type="text" ref={inputRicerca}/>
            </div>
            {isLoaded && <GoogleMap
                mapContainerStyle={{height: '100%', width: '100%'}}
                center={mapCenter}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    disableDefaultUI: true, styles: Config.mapStyles
                }}
            >
                {localiFiltrati.map((m, index) => {
                    return (<Marker
                        position={{lat: parseFloat(m.latitudine), lng: parseFloat(m.longitudine)}}
                        clickable={true}
                        draggable={false}
                        onClick={() => openPreview(index, m)}
                        icon={!previewOpen[index] ? {url: "assets/bandiera.png"} : {url: "assets/bandiera_active.png"}}
                        key={index}
                        ref={el => markersRef.current[index] = el}
                    />);
                })}
            </GoogleMap>}

            <div className={previewOpen.some(el => el) ? 'preview open' : 'preview'}>
                {previewLoading && <Loader/>}
                <div className="chiudi-preview" onClick={closePreview}>
                    <img src="assets/delete.png" alt={"delete"}/>
                </div>
                {locale && locale.copertina &&
                    <div className={"container"} onClick={() => navigate(`/vistavetrina/${locale.id}`)}>
                        <div className={"row titolo"}>
                            <div className={"col s12"}>
                                <span className={"titolo"}>
                                    {locale.nome_attivita}
                                </span>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col s3 immagine"}>
                                <img src={Config.dbBaseUrl + locale.copertina} alt={"copertina"}/>
                            </div>
                            <DettagliAttivita
                                vetrinaCorta={true}
                                vetrina={locale}
                            />
                        </div>
                    </div>}
            </div>
        </div>
    </div>);
}

export default Mappa;