import loadImage from 'blueimp-load-image';

export default {

    fileToDataurl: function (file) {

        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                resolve(e.target.result);
            }

            reader.readAsDataURL(file);
        });
    },

    load: function (url) {

        return new Promise(async (resolve, reject) => {

            if (typeof url === 'object') {
                url = await this.fileToDataurl(url);
            }

            const img = new Image();

            img.addEventListener('load', function () {
                resolve(img);
            });

            img.addEventListener('error', function () {
                reject(new Error('Caricamento immagine fallito'));
            });

            img.src = url;
        });
    },

    resize: function (file, maxWidth, isWidth) {

        const isW = typeof isWidth === 'undefined' ? true : isWidth,
            confObject = {
            canvas: true,
                orientation: true
        };

        if (isW) {
            confObject.maxWidth = maxWidth;
            confObject.minWidth = maxWidth;
        } else {
            confObject.maxHeight = maxWidth;
            confObject.minHeight = maxWidth;
        }

        return new Promise((resolve) => {

            loadImage(file, function (img, data) {
                const dataurl = img.toDataURL("image/jpeg");
                resolve(dataurl);
            }, confObject);
        });
    },

    urltoFile: function (url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                    return res.blob();
                })
                .then(function (buf) {
                    return new Blob([buf], {type: mimeType});
                }));
    },

    imgToFile: function (img) {

        const arr = img.split(','),
            mime = arr[0].match(/:(.*?);/)[1];

        return this.urltoFile(img, 'cropped', mime);
    },

    crop: function (image, width, height, imageWidth, imageHeight, top, left) {

        return new Promise((resolve) => {

            const toTry = {
                canvas: true,
                top: Math.abs(top),
                left: Math.abs(left),
                bottom: (imageHeight - height - Math.abs(top)),
                right: (imageWidth - width - Math.abs(left)),
                minWidth: width,
                minHeight: height
            };


            this.imgToFile(image)
                .then(file => {
                    loadImage(file, function (img) {
                        var dataurl = img.toDataURL("image/jpeg");
                        resolve(dataurl);
                    }, toTry);
                });
        });
    },

}