import React, {useEffect, useState} from "react";
import "../pagesStyle/ModificaDati.scoped.css";
import Loader from "../components/Loader";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import M from "materialize-css";
import dbService from "../utils/DbService";
import {updateProfilo} from "../reducers/accountReducers";

const ModificaDati = () => {
    const [loading, setLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');

    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const handleNome = e => {
        setNome(e.target.value);
    }

    const handleCognome = e => {
        setCognome(e.target.value);
    }

    const modificaVetrina = () => {
        navigate("/modifica-vetrina");
    }

    const modificaDati = () => {
        if (nome.length < 3 || cognome.length < 3) {
            M.toast({html: 'Inserisci un nome e un cognome'});
            return false;
        }

        setLoading(true);

        dbService
            .updateProfilo(user.token, nome, cognome)
            .then(resp => {
                setLoading(false);
                if (resp.status === 'OK') {
                    dispatch(updateProfilo({nome, cognome}));
                    M.toast({html: 'Dati modificati con successo'});
                } else {
                    M.toast({html: 'Controlla i dati inseriti'});
                }
            });
    }

    useEffect(() => {
        setNome(user.nome);
        setCognome(user.cognome);
    }, []);

    return (
        <div className="container">
            <div className="row">

                {loading && <Loader/>}

                <h4 className="logo-blue">Modifica dati</h4>

                <div className="col s12 input-styled">
                    <label htmlFor="nome">Inserisci il nome</label>
                    <input id="nome" type="text" className="validate browser-default" value={nome}
                           onChange={handleNome}/>
                </div>
                <div className="col s12 input-styled">
                    <label htmlFor="cognome">Inserisci il cognome</label>
                    <input id="cognome" type="text" className="validate browser-default" value={cognome}
                           onChange={handleCognome}/>
                </div>
                <div style={{textAlign: 'center'}}>
                    {user.tipologia === 'ESERCENTE' &&
                        <button className="btn" type="submit" name="action" onClick={() => modificaVetrina()}> Modifica
                            Vetrina </button>}
                    &nbsp;
                    <button className="btn" type="submit" name="action" onClick={() => modificaDati()}> Modifica</button>
                </div>
            </div>
        </div>
    );
}

export default ModificaDati;