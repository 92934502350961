import React, {useEffect, useRef, useState} from "react";
import "../pagesStyle/GrattaVinci.scoped.css";
import Loader from "../components/Loader";
import {useNavigate} from "react-router-dom";
import M from 'materialize-css';
import dbService from "../utils/DbService";
import {useSelector} from "react-redux";
import Config from "../config";

const GrattaVinci = () => {
    const [loading, setLoading] = useState(true);
    const [grattaNuovi, setGrattaNuovi] = useState([]);
    const [grattaVecchi, setGrattaVecchi] = useState([]);

    const navigate = useNavigate();
    const pageRef = useRef(null);
    const token = useSelector(state => state.user.token);

    const openGrattaVinci = idGratta => {
        navigate(`/gioca/${idGratta}`);
    }

    useEffect(() => {
        const tabs  = pageRef.current.querySelectorAll('.tabs');
        M.Tabs.init(tabs, {});
        dbService
            .getGrattaVinci(token)
            .then(resp => {
               const grattaVinciVecchi = [],
                   grattaVinciNuovi = [];

               resp.forEach(buono => {
                   if (buono.grattato === "0") {
                       grattaVinciNuovi.push({
                           ...buono,
                           idScontrino: buono.id_scontrino
                       });
                   } else {
                       grattaVinciVecchi.push({
                           ...buono,
                           idScontrino: buono.id_scontrino,
                           immagine: buono.vincente === "1" ? `${Config.subDirectory}/assets/vinto_${buono.quale_vince}.png` : `${Config.subDirectory}/assets/vinto_0.png`
                       });
                   }
               });
               setGrattaNuovi(grattaVinciNuovi);
               setGrattaVecchi(grattaVinciVecchi);
               setLoading(false);
            });
    }, []);

    return (
        <div className="page-completa relativo" ref={pageRef}>
            {loading && <Loader fixed="yes" />}

            <div className={"indietro"} onClick={() => navigate(`/azioni`)}>
                <img src={`${Config.subDirectory}/assets/indietro.png`}  alt={"indietro"}/>
            </div>

            <h4 className="logo-blue">Clicca e Vinci</h4>

            <ul className="tabs">
                <li className="tab col s6">
                    <a href="#nuovi" className="active">Gioca ({grattaNuovi.length})</a>
                </li>
                <li className="tab col s6">
                    <a href="#vecchi">Utilizzati ({grattaVecchi.length})</a>
                </li>
            </ul>

            <div id="nuovi" className="gratta-tab">
                <div className="flexer-grattavinci">
                    {!grattaNuovi.length && <div className="no-buoni">
                        Non vi sono clicca e vinci da usare
                    </div>}
                    {grattaNuovi.map(gratta => (
                        <img src={`${Config.subDirectory}/assets/grattapiccolo.png`} alt={"gratta"} onClick={() => openGrattaVinci(gratta.id)} key={gratta.id}/>
                    ))}
                </div>
            </div>

            <div id="vecchi" className="gratta-tab">
                <div className="flexer-grattavinci">
                    {!grattaVecchi.length && <div className="no-buoni">
                        Non vi sono clicca e vinci usati
                    </div>}
                    {grattaVecchi.map(gratta => (
                        <img src={gratta.immagine} alt={"gratta"} key={gratta.id}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default GrattaVinci;