import React, {useEffect, useState} from "react";
import "../pagesStyle/Concorsi.scoped.css";
import Config from "../config";
import {useNavigate} from "react-router-dom";
import Loader from "../components/Loader";
import dbService from "../utils/DbService";
import {setConcorso as setConcorsoAction} from "../reducers/accountReducers";
import {useDispatch, useSelector} from "react-redux";

const Concorsi = () => {
    const [openConcorso, setOpenConcorso] = useState(false);
    const [loading, setLoading] = useState(true);
    const [concorsi, setConcorsi] = useState([]);
    const [concorso, setConcorso] = useState({});
    const appConcorsi = Config.nomeApp;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);

    const handleOpenConcorso = () => {
        setOpenConcorso(true);
    }

    const handleConcorso = e => {
        setConcorso(e.target.value);
    }

    const cambiaConcorso = () => {
        dispatch(setConcorsoAction(getCurrentConcorso()));
        navigate("/");
    }

    const getCurrentConcorso = () => {
        let current = false;
        concorsi.forEach(item => {
            if (item.id === concorso) {
                current = item;
            }
        });
        return current;
    }

    useEffect(() => {
        dbService
            .getConcorsi(token, '-1')
            .then(resp => {
                setConcorsi(resp);
                setLoading(false);
            })
    }, []);

    return (
        <div className="container">
            {loading && <Loader/>}
            <h4 className="logo-blue">Benvenuto in {appConcorsi}</h4>

            {openConcorso &&
                <select id="concorsi" value={concorso} onChange={handleConcorso} className="browser-default">
                    <option value="">Seleziona un concorso</option>
                    {concorsi.map(item => <option value={item.id} key={item.id}>{item.nome}</option>)}
                </select>
            }
            {!openConcorso &&
                <div className={"btn btn-fullwidth scegli-concorso"} onClick={() => handleOpenConcorso()}>
                    Scegli un concorso
                </div>
            }
            {openConcorso &&
                <div className={"btn btn-fullwidth"} onClick={() => cambiaConcorso()}>
                    Conferma concorso selezionato
                </div>
            }
        </div>
    );
}

export default Concorsi;