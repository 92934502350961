import CordovaModal from "../components/CordovaModal";
import ImageCropper from "../components/ImageCropper";
import React, {useEffect, useRef, useState} from "react";
import placeholder from '../assets/placeholder.png';
import LoaderPercent from "../components/LoaderPercent";
import Loader from "../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import ImmagineCittadino from "../components/ImmagineCittadino";
import BottomBar from "../components/BottomBar";
import M from 'materialize-css';
import {useNavigate} from "react-router-dom";
import {endRegister, login, registerStep1, setConcorso} from "../reducers/accountReducers";
import PrivacyEsercente from "../components/PrivacyEsercente";
import PrivacyCittadino from "../components/PrivacyCittadino";
import "../pagesStyle/Registrazione.scoped.css";
import dbService from "../utils/DbService";
import Config from "../config";

const Registrazione = () => {
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(1);
    const [tipologia, setTipologia] = useState('cittadino');
    const [privacyLettaEsercente, setPrivacyLettaEsercente] = useState(false);
    const [privacyLettaCittadino, setPrivacyLettaCittadino] = useState(false);
    const [informativaEsercente, setInformativaEsercente] = useState(false);
    const [informativaCittadino, setInformativaCittadino] = useState(false);
    const [consenso, setConsenso] = useState('');
    const [cropImg, setCropImg] = useState('');
    const [fotoProfilo, setFotoProfilo] = useState(placeholder);
    const [hasUploaded, setHasUploaded] = useState(false);

    const configUtenti = {
        abilitazioni: {
            cittadino: true,
            esercente: true,
            studente: false
        }
    };
    const tot = 2;
    const datiStore = useSelector(state => state.user.registrazione);
    const nome = datiStore.nome;
    const cognome = datiStore.cognome;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setTipologiaEvent = tipologia => {
        setTipologia(() => tipologia)
    }

    const setValueConsenso = e => {
        setConsenso(() => e.target.value)
    }

    const toggleInformativaEsercente = () => {
        setInformativaEsercente(prev => !prev);
    }

    const toggleInformativaCittadino = () => {
        setInformativaCittadino(prev => !prev);
    }

    const modalPrivacyCittadino = useRef(null);
    const modalPrivacyEsercente = useRef(null);
    const modalCrop = useRef(null);
    const uploadCordova = useRef(null);

    const registrazioneReference = useRef(null);

    const privacyEsercente = () => {
        getModalEsercente().open();
    }

    const fromCordova = b64 => {
        getModalCrop().open();
        setCropImg(() => b64);
    }

    const manageLettaEsercente = e => {
        e.preventDefault();
        setPrivacyLettaEsercente(() => true);
        getModalEsercente().close();
    }

    const manageLettaCittadino = e => {
        e.preventDefault();
        setPrivacyLettaCittadino(() => true);
        getModalCittadino().close();
    }

    const getModalEsercente = () => {
        const modalElement = modalPrivacyEsercente.current;
        return M.Modal.getInstance(modalElement);
    }

    const privacyCittadino = () => {
        getModalCittadino().open();
    }

    const getModalCittadino = () => {
        const modalElement = modalPrivacyCittadino.current;
        return M.Modal.getInstance(modalElement);
    }

    const getModalCrop = () => {
        const modalElement = modalCrop.current;
        return M.Modal.getInstance(modalElement);
    }

    const handleUpload = () => {
        const file = registrazioneReference.current.querySelector("#upload-propic").files[0];
        if (!file) return true;
        setCropImg(() => file);
        getModalCrop().open();
    }

    const eliminaUpload = () => {
        setHasUploaded(() => false);
        setFotoProfilo(() => placeholder);
    }

    const forward = () => {
        if (current < tot) {
            setCurrent(prev => prev + 1);
        }
    }

    const backward = () => {
        if (current > 1) {
            setCurrent(prev => prev - 1);
        }
    }

    const validate = () => {
        switch (current) {
            case 1:
                if (!tipologia) {
                    M.toast({ html: 'Scegli come userai l\'app prima di continuare' });
                    return false;
                }
                if (tipologia === 'cittadino' && (!informativaCittadino || consenso === '') ) {
                    M.toast({ html: 'Devi accettare l\'informativa per continuare' });
                    return false;
                }
                if (tipologia === 'esercente' && !informativaEsercente ) {
                    M.toast({ html: 'Devi accettare l\'informativa per continuare' });
                    return false;
                }
                forward();
                break;
            case 2:
                if (tipologia === 'esercente') {
                    dispatch(registerStep1(
                        {
                            fotoProfilo: hasUploaded ? fotoProfilo : 'PLACEHOLDER',
                            tipologia: 'ESERCENTE'
                        }
                    ));
                    navigate('/creavetrina');
                } else {
                    registerCittadino();
                }
                break;
            default:
                M.toast({ html: 'Qualcosa non ha funzionato' });
                break;
        }
    }

    const cittadinoToDb = fotoProfilo => {
        dbService
            .registra({
                tipologia: tipologia.toUpperCase(),
                nome: datiStore.nome,
                cognome: datiStore.cognome,
                email: datiStore.email,
                fotoProfilo,
                consenso,
                abilitato: true,
                password: datiStore.password
            })
            .then(resp => {
                setLoading(() => false);
                const userData = {
                    uid: resp.uid,
                    nome: datiStore.nome,
                    cognome: datiStore.cognome,
                    fotoProfilo: fotoProfilo === 'PLACEHOLDER' ? placeholder : fotoProfilo,
                    tipologia: tipologia.toUpperCase(),
                    token: resp.token
                }
                if (Config.singoloConcorso) {
                    let concorso = false;
                    dbService
                        .getConcorsi(resp.token, Config.idSingoloConcorso)
                        .then(resp => {
                            concorso = resp.concorso[0];
                            dispatch(login(userData));
                            dispatch(setConcorso(concorso));
                            dispatch(endRegister({}));
                            navigate("/");
                        })
                } else {
                    dispatch(login(userData));
                    dispatch(endRegister({}));
                    navigate("/concorsi");
                }
            })

    }

    const registerCittadino = () => {
        setLoading(() => true);
        if (!hasUploaded) {
            cittadinoToDb('PLACEHOLDER');
        } else {
            dbService
                .uploadFotoProfilo(fotoProfilo, datiStore.email)
                .then(resp => {
                    cittadinoToDb(resp.url_image);
                });
        }
    }

    const closeCrop = () => {
        getModalCrop().close();
    }

    const uploadProfilo = img => {
        closeCrop();
        setFotoProfilo(() => {
            setHasUploaded(() => true);
            return img;
        });
    }

    useEffect(() => {
        setPercent(() => 100 * current / tot);
    }, [current]);

    useEffect(() => {
        const elems = registrazioneReference.current.querySelectorAll('.modal');
        M.Modal.init(elems, {});
    }, []);

    return (
        <div className="registrazione" ref={registrazioneReference}>
            <LoaderPercent percent={percent} />

            {loading && <Loader />}

            <div className="page-container">

                {/*<!-- Pagina di scelta della tipologia account -->*/}
                <div className={current === 1 ? 'page active' : 'page'} data-id="1">
                    <h3>Ciao</h3>
                    <h2>{nome} {cognome}</h2>
                    <p>Come vuoi usare la nostra app?</p>

                    <div className="container">
                        <div className="account-types" style={{textAlign:'center'}}>
                            {configUtenti.abilitazioni.esercente && <div className={tipologia === 'esercente' ? 'tipo-account active' : 'tipo-account'}
                                 onClick={() => setTipologiaEvent('esercente')}>
                                <div className="contenitore">
                                    {tipologia === 'esercente' && <div className="tick">
                                        <img src="assets/tick.png" alt={"tick"}/>
                                    </div>}
                                    <ImmagineCittadino />
                                </div>

                                <span>Esercente</span>
                            </div>}

                            {configUtenti.abilitazioni.cittadino && <div className={tipologia === 'cittadino' ? 'tipo-account active' : 'tipo-account'} onClick={() => setTipologiaEvent('cittadino')}>
                                <div className="contenitore">
                                    {tipologia === 'cittadino' && <div className="tick">
                                        <img src="assets/tick.png" alt={"tick"}/>
                                    </div>}
                                    <ImmagineCittadino />
                                </div>

                                <span>Cittadino</span>
                            </div>}

                            {configUtenti.abilitazioni.studente && <div className={tipologia === 'studente' ? 'tipo-account active' : 'tipo-account'}
                                 onClick={() => setTipologiaEvent('studente')}>
                                <div className="contenitore">
                                    {tipologia === 'studente' && <div className="tick">
                                        <img src="assets/tick.png" alt={"tick"}/>
                                    </div>}
                                    <ImmagineCittadino />
                                </div>

                                <span>UPO Alumni</span>
                            </div>}
                        </div>
                        {tipologia === 'esercente' &&
                            <div className="descrizione-account">
                                <p className="nobottom">L'<b>esercente</b> può usare l'app per scansionare i buoni dei cittadini e pubblicizzare la propria attività</p>

                                <div className="privacy">
                                    {!privacyLettaEsercente &&
                                        <div className="privacy-overlay" onClick={privacyEsercente}></div>
                                    }

                                    <p className="nobottom">
                                        <label>
                                            <input type="checkbox"
                                                   checked={informativaEsercente}
                                                   onChange={toggleInformativaEsercente}/>
                                            <span>Dichiaro di aver preso visione dell’<span style={{textDecoration: "underline"}} onClick={privacyEsercente}>informativa privacy</span></span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        }
                        {tipologia === 'cittadino' &&
                            <div className="descrizione-account">
                                <p className="nobottom">Il <b>cittadino</b> può usare l'app per vincere ed usare i buoni da utilizzare con gli esercenti convenzionati</p>

                                <div className="privacy">
                                    {!privacyLettaCittadino && <div className="privacy-overlay" onClick={privacyCittadino}></div>}
                                    <p className="nobottom">
                                        <label>
                                            <input type="checkbox"
                                                   checked={informativaCittadino}
                                                   onChange={toggleInformativaCittadino}/>
                                            <span>Dichiaro di aver preso visione dell’<span style={{textDecoration: "underline"}} onClick={privacyCittadino}>informativa privacy</span></span>
                                        </label>
                                    </p>

                                    <p className="nobottom mleftmin">
                                        <label>
                                            <input type="radio" name="consenso" checked={consenso === 'Presto'} value="Presto" onChange={setValueConsenso}/>
                                            <span>Presto il mio consenso per l’utilizzo dei miei dati ricevere informazioni commerciali mirate, sulla base dei miei interessi personali e dei miei comportamenti di acquisto (finalità II.2.ii dell’informativa)</span>
                                        </label>
                                    </p>
                                    <p className="nobottom mleftmin">
                                        <label>
                                            <input type="radio" name="consenso" checked={consenso === 'Nego'} value="Nego" onChange={setValueConsenso}/>
                                            <span>Nego il mio consenso per l’utilizzo dei miei dati ricevere informazioni commerciali mirate, sulla base dei miei interessi personali e dei miei comportamenti di acquisto (finalità II.2.ii dell’informativa)</span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        }
                        {tipologia === 'studente' &&
                            <div className="descrizione-account">
                                <p className="nobottom">Iscrivendoti come <b>UPO Alumni</b> può usare l'app per vincere ed usare i buoni da utilizzare con gli esercenti convenzionati. Avrà inoltre accesso a sconti speciali negli esercizi convenzionati</p>

                                <div className="privacy">
                                    {!privacyLettaCittadino && <div className="privacy-overlay" onClick={privacyCittadino}></div>}
                                    <p className="nobottom">
                                        <label>
                                            <input type="checkbox" checked={informativaCittadino} onChange={toggleInformativaCittadino} />
                                            <span>Dichiaro di aver preso visione dell’<span style={{textDecoration: "underline"}} onClick={privacyCittadino}>informativa privacy</span></span>
                                        </label>
                                    </p>

                                    <p className="nobottom mleftmin">
                                        <label>
                                            <input type="radio" name="consenso" checked={consenso === 'Presto'} value="Presto" onChange={setValueConsenso} />
                                            <span>Presto il mio consenso per l’utilizzo dei miei dati ricevere informazioni commerciali mirate, sulla base dei miei interessi personali e dei miei comportamenti di acquisto (finalità II.2.ii dell’informativa)</span>
                                        </label>
                                    </p>
                                    <p className="nobottom mleftmin">
                                        <label>
                                            <input type="radio" name="consenso" checked={consenso === 'Nego'} value="Nego" onChange={setValueConsenso} />
                                            <span>Nego il mio consenso per l’utilizzo dei miei dati ricevere informazioni commerciali mirate, sulla base dei miei interessi personali e dei miei comportamenti di acquisto (finalità II.2.ii dell’informativa)</span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={current === 2 ? 'page active' : 'page'} data-id="2">
                    <h4 className="logo-blue">Aggiungi una foto profilo</h4>

                    <div className="foto-profilo">
                    <input type="file" id="upload-propic" onChange={handleUpload} accept="image/x-png,image/gif,image/jpeg" capture={"environment"} />

                    <div className="cerchio-foto">
                        <img className="uploaded" src={fotoProfilo} alt={"foto profilo"} />
                    </div>
                        {!hasUploaded &&
                            <div className="fotocamera" >
                                <img src="assets/fotocamera_white.png" alt={"upload foto"}/>
                            </div>
                        }
                        {hasUploaded &&
                            <div className="fotocamera elimina" onClick={eliminaUpload}>
                                <img src="assets/cestino.png" alt={"elimina foto"}/>
                            </div>
                        }
                    </div>
                    <p className="testo-descrittivo">Carica una foto oppure usa l'icona predefinita, semplicemente premendo "Fine"</p>
                </div>
                <BottomBar forward={validate} backward={backward} tot={tot} current={current} />
            </div>
            <div id="privacy-esercente" className="modal" ref={modalPrivacyEsercente}>
                <div className="modal-content">
                    <h4>Informativa Privacy</h4>
                    <PrivacyEsercente />
                </div>
                <div className="modal-footer">
                    <p className="waves-effect waves-green btn-flat" onClick={manageLettaEsercente}>Ho letto l'informativa</p>
                </div>
            </div>
            <div id="privacy-cittadino" className="modal" ref={modalPrivacyCittadino}>
                <div className="modal-content">
                    <h4>Informativa Privacy</h4>
                    <PrivacyCittadino />
                </div>
                <div className="modal-footer">
                    <p className="waves-effect waves-green btn-flat" onClick={manageLettaCittadino}>Ho letto l'informativa</p>
                </div>
            </div>
            <div id="crop-modal" className="modal" ref={modalCrop}>
                <ImageCropper imageData={cropImg} cropped={uploadProfilo} annulla={closeCrop} />
            </div>
            <CordovaModal ref={uploadCordova} upload={fromCordova} />
        </div>
    );
}

export default Registrazione;