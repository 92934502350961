import React from "react"
import "../componentStyle/BottomBarModificaVetrina.scoped.css";

const BottomBarModificaVetrina = ({forward, backward, tot, current, toProfile}) => {
    return (
        <div className="bottom-bar">
            {current > 1 && <div className={"btn btn-flat"} onClick={() => backward()}>Indietro</div>}
            {current === 1 && <div className={"btn btn-flat"} onClick={() => toProfile()}>Indietro</div>}
            <div className="pagination">{current} / {tot}</div>

            <div className="btn" onClick={() => forward()}>{tot === current ? 'Fine' : 'Avanti'}</div>
        </div>
    );
}

export default BottomBarModificaVetrina;