import React, {useEffect, useState} from "react";
import M from 'materialize-css';
import {useDispatch} from "react-redux";
import {startRegister} from "../reducers/accountReducers";
import {useNavigate} from "react-router-dom";
import dbService from "../utils/DbService";
import "../pagesStyle/FormRegistrazione.scoped.css";

const FormRegistrazione = ({homeLoading}) => {
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validateString = value => value.replace(/[^a-zA-Z\-\s]/g, '');

    const handleNome = e => {
        setNome(() => validateString(e.target.value));
    }

    const handleCognome = e => {
        setCognome(() => validateString(e.target.value));
    }

    const handleEmail = e => {
        setEmail(() => e.target.value);
    }

    const handlePassword = e => {
        setPassword(() => e.target.value);
    }

    const onRegister = () => {
        homeLoading(() => true);
        if (!nome || !cognome) {
            M.toast({html: 'Compila tutti i campi per continuare'});
            homeLoading(() => false);
            return false;
        }
        dbService
            .checkExistance(email)
            .then(resp => {
                if (resp.status === 'OK') {
                    M.toast({html: 'Questa email è già stata utilizzata'});
                    homeLoading(() => false);
                } else {
                    dispatch(startRegister({
                        nome, cognome, email, password
                    }));
                    navigate("/registrazione");
                }
            })
    }

    return (<div className="row">
        <div className="col s12 input-styled">
            <label htmlFor="nome">Inserisci il nome</label>
            <input id="nome"
                   onChange={handleNome}
                   type="text"
                   className="validate browser-default"
                   maxLength="30"
                   value={nome}/>
        </div>
        <div className="col s12 input-styled">
            <label htmlFor="cognome">Inserisci il cognome</label>
            <input id="cognome"
                   onChange={handleCognome}
                   type="text"
                   className="validate browser-default"
                   maxLength="30"
                   value={cognome}/>
        </div>
        <div className="col s12 input-styled">
            <label htmlFor="email">Inserisci email</label>
            <input id="email-reg"
                   type="email"
                   className="validate browser-default"
                   maxLength="50"
                   value={email}
                   onChange={handleEmail}
            />
        </div>
        <div className="col s12 input-styled">
            <label htmlFor="password">Inserisci password</label>
            <input id="password-reg"
                   type="password"
                   className="validate browser-default"
                   maxLength="30"
                   value={password}
                   onChange={handlePassword}
            />
        </div>
        <div style={{textAlign: 'center'}}>
        <button className="btn waves-effect waves-light"
                type="submit"
                name="action" onClick={onRegister}> Registrati
        </button>
        </div>
    </div>);
}

export default FormRegistrazione;