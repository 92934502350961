import React, {forwardRef, useImperativeHandle, useState} from "react";
import "../componentStyle/OrarioVetrina.scoped.css";
import Orario from "./Orario";

const OrarioVetrina = forwardRef((props, ref) => {

    const [orari, setOrari] = useState(props.dati ? props.dati.orari.map(orario => {
        return {
            id: parseInt(orario.id_orario),
            continuato: orario.continuato === "1",
            inizio_1: orario.inizio_1,
            inizio_2: orario.inizio_2,
            fine_1: orario.fine_1,
            fine_2: orario.fine_2,
            giorni: orario.giorni.split(",")
        }
    }) : [{
        id: 1, giorni: [], inizio_1: '', fine_1: '', continuato: true, inizio_2: '', fine_2: ''
    }]);
    const [lastId, setLastId] = useState(1);
    const validate = () => {
        const timeRegex = /^(?:[01]\d|2[0123]):(?:[012345]\d)$/;
        if (!orari.length) {
            return false;
        }
        for (let index = 0; index < orari.length; index++) {
            if (!orari[index].giorni.length) {
                return false;
            }
            if (!timeRegex.test(orari[index].inizio_1) || !timeRegex.test(orari[index].fine_1)) {
                return false;
            }
            if (!orari[index].continuato) {
                if (!timeRegex.test(orari[index].inizio_2) || !timeRegex.test(orari[index].fine_2)) {
                    return false;
                }
            }
        }
        return true;
    }

    const getData = () => orari;

    useImperativeHandle(ref, () => ({
        validate, getData
    }));

    const modificaOrario = (key, dati) => {
        const tempOrari = orari;
        for (let index = 0; index < orari.length; index++) {
            if (orari[index].id === key) {
                tempOrari[index] = dati;
            }
        }
        setOrari(() => tempOrari);
    }

    const eliminaOrario = key => {
        setOrari(prev => prev.filter(o => o.id !== key))
    }

    const addOrario = e => {
        e.preventDefault();
        const nuoviOrari = orari;
        nuoviOrari.push({
            id: lastId + 1, giorni: [], inizio_1: '', fine_1: '', continuato: true, inizio_2: '', fine_2: ''
        });
        setOrari(() => nuoviOrari);
        setLastId(() => lastId + 1);
    }

    return (<div className="container lefter">
            <h4 className="logo-blue">Orario</h4>
            {orari.map((orario, index) => (<Orario
                    options={orario}
                    elimina={eliminaOrario}
                    modificaOrario={modificaOrario}
                    key={orario.id}/>))}

            <p onClick={addOrario}>Aggiungi altri giorni</p>
        </div>);
});

export default OrarioVetrina;