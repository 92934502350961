import FormRegistrazione from "./FormRegistrazione";
import React, {useEffect, useRef, useState} from "react";
import M from 'materialize-css';
import "../pagesStyle/Home.scoped.css";
import {useDispatch} from "react-redux";
import {login, setConcorso, setRecuperoPassword} from "../reducers/accountReducers";
import dbService from '../utils/DbService';
import Config from "../config";
import {useNavigate} from "react-router-dom";

const Home = () => {
    useEffect(() => {
        const tabs = homeRef.current.querySelectorAll(".tabs");
        M.Tabs.init(tabs, {});
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [homeLoading, setHomeLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const homeRef = useRef(null);

    const onLogin = () => {
        setHomeLoading(() => true);
        dbService
            .login(email, password)
            .then(resp => {
                if (resp.status === 'OK') {
                    const userData = {
                        token: resp.token,
                        nome: resp.dati.nome,
                        cognome: resp.dati.cognome,
                        email: resp.dati.email,
                        tipologia: resp.dati.tipologia,
                        fotoProfilo: resp.dati.fotoProfilo,
                        vetrina: resp.dati.vetrina
                    };
                    if (Config.singoloConcorso) {
                        let concorso = false;
                        dbService
                            .getConcorsi(resp.token, Config.idSingoloConcorso)
                            .then(resp => {
                                concorso = resp.concorso[0];
                                dispatch(login(userData));
                                dispatch(setConcorso(concorso));
                                navigate('/azioni');
                            })
                    } else {
                        dispatch(login(userData));
                        navigate('/concorsi');
                    }
                } else {
                    setHomeLoading(() => false);
                    M.toast({
                        html: 'Controlla le informazioni inserite'
                    });
                }
            });
    }

    const handleEmailChange = e => {
        setEmail(() => e.target.value);
    }

    const handlePasswordChange = e => {
        setPassword(() => e.target.value);
    }

    const onRecupera = () => {
        dispatch(setRecuperoPassword(true));
        navigate("/recupera");
    }

    return (<div className="home" ref={homeRef}>
        {homeLoading && <div className="progress">
            <div className="indeterminate"></div>
        </div>}

        <div className="logo-container">
            <img alt="Ascom Buoni"
                 className="main-logo"
                 src={Config.logoHome}/>
        </div>

        <div className="container home-container">
            <div className="row">

                <ul className="tabs">
                    <li className="tab col s6">
                        <a href="#login" className="active">Login</a>
                    </li>
                    <li className="tab col s6">
                        <a href="#register">Registrazione</a>
                    </li>
                </ul>

                <div id="login" className="home-tab">
                    <div className="col s12 input-styled">
                        <label htmlFor="email">Inserisci email</label>
                        <input id="email"
                               type="email"
                               className="validate browser-default"
                               value={email}
                               onChange={handleEmailChange}
                        />
                    </div>
                    <div className="col s12 input-styled">
                        <label htmlFor="password"
                               className="">Inserisci password</label>
                        <input id="password"
                               type="password"
                               className="validate browser-default"
                               value={password}
                               onChange={handlePasswordChange}
                        />
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <button className="btn"
                                type="submit"
                                name="action"
                                onClick={onLogin}> Accedi
                        </button>
                    </div>


                    <p className="margin-top">
                        <p onClick={() => onRecupera()} className={"standard-link"}>Hai scordato la password?</p>
                    </p>
                </div>

                <div id="register">
                    <FormRegistrazione homeLoading={setHomeLoading}/>
                </div>
            </div>
        </div>

    </div>);
}

export default Home;
