import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import "../componentStyle/ImmaginiVetrina.scoped.css";
import ImageCropper from "./ImageCropper";
import CordovaModal from "./CordovaModal";
import M from 'materialize-css';
import Config from "../config";

const ImmaginiVetrina = forwardRef((props, ref) => {
    const [hasCopertina, setHasCopertina] = useState(props.dati && props.dati.copertina !== 'PLACEHOLDER');
    const [copertina, setCopertina] = useState(props.dati && props.dati.copertina ? Config.dbBaseUrl + props.dati.copertina : '');
    const [cropImg, setCropImg] = useState('');
    const [galleria, setGalleria] = useState([]);

    const containerRef = useRef(null);
    const modalCrop = useRef(null);
    const uploadCordova = useRef(null);

    useEffect(() => {
        const elems = containerRef.current.querySelectorAll(".modal");
        M.Modal.init(elems, {
            dismissible: false
        });
    }, []);

    const uploadCopertina = e => {
        const file = containerRef.current.querySelector("#upload-copertina").files[0];
        if (!file) {
            return true;
        }
        setCropImg(() => file);
        getModalCrop().open();
    }

    const getModalCrop = () => M.Modal.getInstance(modalCrop.current);

    const eliminaCopertina = () => {
        setCopertina('');
        setHasCopertina(false);
    }

    const uploadGalleria = () => {
        const file = containerRef.current.querySelector("#upload-galleria").files[0];
        if (!file) {
            return true;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setGalleria(prev => {
                const temp = prev;
                temp.push(reader.result);
                return temp;
            });
        }
    }

    const eliminaGalleria = key => {
        setGalleria(prev => {
            const temp = [];
            prev.forEach((el, index) => {
                if (index !== key) {
                    temp.push(el);
                }
            });
            return temp;
        })
    }

    const getData = () => {
        return {
            copertina, galleria
        }
    }

    const uploadCropped = img => {
        closeCrop();
        setCopertina(() => img);
        setHasCopertina(() => true);
    }

    const closeCrop = () => {
        getModalCrop().close();
    }

    const fromCordova = b64 => {
        getModalCrop().open();
        setCropImg(() => b64);
    }

    useImperativeHandle(ref, () => ({
        getData
    }));

    return (<div className="container lefter" ref={containerRef}>
        <h4 className="logo-blue">Immagini</h4>

        <div class="row">
            <div className="col s12 input-styled">
                <label htmlFor="immagineCopertina">Copertina</label>

                <div className="container-copertina">
                    <div className="background" style={{backgroundImage: "url('" + copertina + "')"}}>
                        <input type="file" id="upload-copertina" onChange={uploadCopertina}
                               accept="image/x-png,image/gif,image/jpeg"/>

                        {!hasCopertina && <div className="fotocamera">
                            <img src="assets/fotocamera_white.png" alt={"carica copertina"}/>
                        </div>}

                        {hasCopertina && <div className="elimina-copertina" onClick={eliminaCopertina}>
                            <img src="assets/cestino.png" alt={"elimina copertina"}/>
                        </div>}
                    </div>
                </div>
            </div>
            {Config.useGalleria && <div className="col s12 input-styled">
                <label htmlFor="immagineCopertina">Galleria</label>

                <div className="flexer">
                    <div className="add-galleria blocco-galleria">
                        <input type="file" id="upload-galleria" onChange={uploadGalleria}
                               accept="image/x-png,image/gif,image/jpeg"/>
                        <img src="assets/piu2.png" alt={"aggiungi immagine galleria"}/>
                    </div>
                    {galleria.map((immagine, key) => (<div className={"blocco-galleria"} key={key}>
                        <img src={immagine} alt={"galleria-" + key}/>
                        <div className={"elimina-galleria"} onClick={() => eliminaGalleria(key)}>
                            <img src="assets/cestino.png" alt={"elimina galleria-" + key}/>
                        </div>
                    </div>))}
                </div>
            </div>}
        </div>

        <div id="crop-modal" className="modal" ref={modalCrop}>
            <ImageCropper
                widthParam={320}
                heightParam={110}
                imageData={cropImg}
                cropped={uploadCropped}
                annulla={closeCrop}/>
        </div>

        <CordovaModal ref={uploadCordova} upload={fromCordova}/>
    </div>);
});

export default ImmaginiVetrina;