import React from "react";

const PrivacyCittadino = () => {
    return (<div style={{textAlign: "left"}}>
            <p>I) Primo livello di informazione<br/>I.1) Titolare del trattamento<br/>Comtur Vercellese Servizi Srl PIVA
                .......<br/>I.2) Finalit&agrave; del trattamento<br/>i. gestione, tramite l&rsquo;APP, di un sistema di
                buoni sconto da spendere negli esercizi convenzionati<br/>ii. offerta di informazioni commerciali
                mirate, sulla base dei tuoi interessi personali e dei tuoi comportamenti di<br/>acquisto<br/>iii.
                esercizio di legittimi interessi di Comtur Vercellese Servizi S.r.l.<br/>I.3) Tipologie di dati raccolti<br/>Dati
                anagrafici, dati di contatto, dati di accesso all&rsquo;APP, immagine fotografica, tipo di consenso,
                dati d&rsquo;uso dell&rsquo;APP,<br/>fonte informativa, acquisti effettuati, buoni raccolti / posseduti
                / utilizzati, notifiche del sistema, dati per la<br/>costruzione del tuo profilo personale
                (attivit&agrave; di profilazione)<br/>Trovi un elenco dettagliato dei dati al paragrafo II.3) Quali dati
                trattiamo della sezione II) Secondo Livello di<br/>informazione, che ti invitiamo a leggere con
                attenzione.<br/>I.4) Comunicazione a terzi<br/>Fornitori di servizi o soggetti verso cui esistono
                obblighi di legge che impongono la comunicazione.<br/>Nessun trasferimento dei dati al di fuori dello
                Spazio Economico Europeo.<br/>Al paragrafo paragrafo II.5) A chi possiamo comunicare i tuoi dati della
                sezione II) Secondo Livello di informazione,<br/>trovi una descrizione puntuale delle categorie di
                soggetti con cui condividiamo i tuoi dati. Ti invitiamo a leggere con<br/>attenzione tale documentazione<br/>I.5)
                Obbligatoriet&agrave; del conferimento e conseguenze del mancato consenso<br/>&bull; Dati obbligatori
                (quelli indicati con # sono raccolti in automatico): dati anagrafici (nome, cognome, data di<br/>nascita);
                dati di contatto (e-mail e numero di telefono); dati di accesso all&rsquo;APP (e-mail e password); dati
                relativi<br/>ai consensi prestati (tipo di consenso, data# e ora# di raccolta); dati relativi
                all&rsquo;uso dell&rsquo;APP (indirizzo IP# e<br/>sistema operativo del device utilizzato per
                connettersi#), dati relativi agli acquisti effettuati; dati relativi ai buoni<br/>raccolti, posseduti e
                utilizzati; dati relativi alle notifiche del sistema#.<br/>Trattamento in base al contratto di utilizzo
                stipulato.<br/>Senza il conferimento dei dati non si pu&ograve; utilizzare l&rsquo;APP<br/>&bull; Dati
                facoltativi: immagine; hobby e interessi; composizione del nucleo famigliare; dati relativi alle
                ricerche<br/>effettuate con l&rsquo;APP#; dati relativi agli acquisti effettuati#; dati relativi al
                numero di buoni raccolti, posseduti,<br/>utilizzati #.<br/>Trattamento in base al consenso<br/>Negando
                il consenso &egrave; possibile usare l&rsquo;APP ma non si ricevono informazioni commerciali mirate e
                non si<br/>pu&ograve; caricare la foto nel proprio profilo<br/>I.6) Diritti e modalit&agrave; di
                esercizio<br/>diritto accesso, rettifica, cancellazione, opposizione al trattamento, limitazione del
                trattamento.<br/>Impossibile esercitare il diritto alla portabilit&agrave;.<br/>Per l&rsquo;esercizio
                dei diritti:<br/>&bull; utilizzare l&rsquo;area personale dell&rsquo;APP<br/>&bull; inviare richiesta a
            </p>
            <p>a) e-mail: privacy@ascomvc.it</p>
            <p>e-mail dpo.comtur@ascomvc.it<br/>pec comtur@pec.ascomvc.it<br/>Per saperne di pi&ugrave; su come
                trattiamo i tuoi dati personali, ti invitiamo a leggere la nostra informativa completa,<br/>presentata
                di seguito</p>
            <p>II) Secondo livello di informazione<br/>Per Comtur Vercellese Servizi S.r.l. &egrave; una
                priorit&agrave; garantire in ogni momento la massima tutela dei dati che ci<br/>vengono affidati. Uno
                degli aspetti che riteniamo cruciali &egrave; che tu sia correttamente informato circa le
                finalit&agrave; e le<br/>modalit&agrave; con cui trattiamo i tuoi dati e circa i tuoi diritti e le loro
                modalit&agrave; di esercizio.<br/>Di seguito trovi nel dettaglio le informazioni che siamo tenuti a
                fornirti ai sensi dell&rsquo;articolo 13 del Regolamento (UE)<br/>2016/679 del Parlamento Europeo e del
                Consiglio, del 27 aprile 2016, relativo alla protezione delle persone fisiche<br/>con riguardo al
                trattamento dei dati personali, nonch&eacute; alla libera circolazione di tali dati (d&rsquo;ora in poi
                chiamato<br/>semplicemente GDPR)<br/>II.1) Chi &egrave; il Titolare del trattamento dei dati<br/>Il
                Titolare del trattamento &egrave; Comtur Vercellese Servizi S.r.l. - P.IVA 0578570028, avente sede
                legale in Via<br/>Duchessa Jolanda n. 26 &ndash; 13100 Vercelli (di seguito &ldquo;noi&rdquo;)<br/>Puoi
                contattarci tramite i seguenti canali:<br/>a) e-mail: privacy@ascomvc.it<br/>b) pec
                comtur@pec.ascomvc.it<br/>Ti segnaliamo inoltre che puoi contattare il nostro Responsabile per la
                protezione dei dati, inviando una mail a<br/>dpo.comtur@ascomvc.it<br/>II.2) Per quali
                finalit&agrave; trattiamo i dati<br/>Trattiamo i tuoi dati per le seguenti finalit&agrave;:<br/>iv.
                permetterti di ottenere e utilizzare tramite l&rsquo;APP buoni sconto da spendere negli esercizi
                convenzionati<br/>v. offrirti informazioni commerciali mirate, sulla base dei tuoi interessi personali e
                dei tuoi comportamenti di<br/>acquisto (attivit&agrave; di profilazione)<br/>vi. tutelare i nostri
                legittimi interessi ed esercitare i nostri diritti<br/>&bull; II.3) Quali dati trattiamo<br/>Il
                trattamento riguarda i dati elencati di seguito. Ti segnaliamo che per poter utilizzare
                l&rsquo;APP &egrave; necessario<br/>che tu fornisca i dati segnati con * e che i dati segnati con #
                (ugualmente necessari) vengono raccolti in<br/>automatico tramite l&rsquo;uso
                dell&rsquo;APP<br/>&bull; Dati anagrafici:<br/>◦ Nome *<br/>◦ Cognome*<br/>◦ Sesso<br/>◦ Data di nascita
                *<br/>◦ Comune di residenza / domicilio<br/>&bull; Dati di contatto:<br/>◦ indirizzo e-mail *<br/>◦
                numero di telefono *<br/>&bull; Dati di accesso<br/>◦ indirizzo e-mail *<br/>◦ password * (non visibile
                da parte dell'amministratore di sistema)<br/>◦ customer ID #<br/>&bull; Immagine
                fotografica<br/>&bull; Dati relativi al consenso prestato:<br/>◦ consensi prestati / negati *<br/>◦ data
                e ora di raccolta del consenso #<br/>&bull; Dati relativi all'utilizzo dell'app #<br/>◦ indirizzo
                IP<br/>◦ sistema operativo<br/>&bull; Dati relativi alla modalit&agrave; con cui si &egrave; conosciuto
                il servizio (passaparola, tipologia di social, pubblicit&agrave;)<br/>&bull; Dati relativi agli acquisti
                effettuati * (necessari per la gestione dei buoni acquisto)<br/>◦ data acquisto<br/>◦ importo speso<br/>◦
                Comune in cui &egrave; stato effettuato l'acquisto<br/>◦ esercizio in cui &egrave; stato effettuato
                l'acquisto<br/>◦ tipologia di categoria merceologica dell'acquisto<br/>&bull; Dati relativi al numero di
                buoni raccolti, posseduti, utilizzati<br/>◦ data di acquisizione del buono *<br/>◦ valore del buono
                *<br/>◦ data di utilizzo del buono *<br/>◦ esercizio in cui il buono &egrave; stato utilizzato
                *<br/>&bull; Dati relativi alle notifiche inviate dal sistema<br/>◦ data #<br/>◦ ora #<br/>◦ contenuto
                del messaggio #<br/>&bull; Dati di profilazione<br/>◦ dati relativi agli hobby e agli interessi
                suddivisi nelle seguenti categorie:</p>
            <p>▪ alimentari<br/>▪ ristorazione e intrattenimento<br/>▪ abbigliamento e accessori<br/>▪ sport e tempo
                libero<br/>▪ salute e benessere<br/>▪ tecnologia e giochi<br/>▪ libri e scuola<br/>▪ articoli e servizi
                per la casa<br/>▪ fai da te, fiori e giardinaggio<br/>▪ motori<br/>▪ altri servizi<br/>◦ composizione
                del nucleo famigliare:<br/>▪ presenza di figli<br/>▪ et&agrave; degli eventuali figli (0-9, 10-17,
                18+)<br/>▪ presenza di animali domestici<br/>▪ tipologia degli eventuali animali domestici<br/>◦ dati
                relativi alle ricerche effettuate #<br/>▪ Comune a cui si riferiva la ricerca<br/>▪ tipologia di
                esercizi / categorie merceologiche ricercate<br/>▪ data e ora della ricerca<br/>◦ dati relativi agli
                acquisti effettuati #<br/>▪ data acquisto<br/>▪ importo speso<br/>▪ Comune in cui &egrave; stato
                effettuato l'acquisto<br/>▪ esercizio in cui &egrave; stato effettuato l'acquisto<br/>▪ tipologia di
                categoria merceologica dell'acquisto<br/>◦ Dati relativi al numero di buoni raccolti, posseduti,
                utilizzati #<br/>▪ data di acquisizione del buono<br/>▪ valore del buono<br/>▪ data di utilizzo del
                buono<br/>▪ esercizio in cui il buono &egrave; stato utilizzato<br/>&bull; II.4) Su quali basi
                giuridiche si fonda il trattamento dei dati<br/>La legittimit&agrave; del trattamento dei tuoi
                dati &egrave; fondata sulle seguenti basi:<br/>a) l'esistenza di un contratto stipulato con te, ai sensi
                del DGPR art. 6 comma 1 lett. b), relativo al sistema<br/>di ottenimento e spendita dei buoni sconto
                nonch&eacute; alla partecipazione al gioco a premi in modalit&agrave; "gratta e<br/>vinci"<br/>b) il
                consenso da te liberamente prestato, ai sensi dell&rsquo;articolo 6 punto 1 lettera a) del GDPR per
                quanto<br/>attiene il trattamento della tua immagine fotografica (il consenso si intende prestato con il
                caricamento<br/>dell&rsquo;immagine da parte tua nel nostro sistema e si intende revocato con la
                cancellazione da parte tua<br/>dell&rsquo;immagine stessa)<br/>c) il consenso da te liberamente
                prestato, ai sensi dell&rsquo;articolo 6 punto 1 lettera a) del GDPR per quanto<br/>attiene le
                attivit&agrave; di profilazione e di marketing<br/>d) l&rsquo;esercizio di nostri legittimi interessi,
                ai sensi dell&rsquo;articolo 6 punto 1 lettera f) del GDPR. Tali interessi consistono:<br/>i.
                nell&rsquo;esercizio dei nostri diritti, quali ad esempio, la difesa in giudizio<br/>ii.
                nell&rsquo;invio di comunicazioni legate ai beni o servizi che hai acquistato presso gli esercizi
                convenzionati<br/>iii. nella rilevazione della qualit&agrave; dei servizi e della soddisfazione delle
                persone che utilizzano l&rsquo;APP<br/>i.<br/>&bull; II.5) A chi possiamo comunicare i tuoi dati<br/>Comunichiamo
                i tuoi dati solamente ai soggetti strettamente necessari per la gestione dell&rsquo;APP. Si tratta di
                societ&agrave;,<br/>consulenti e liberi professionisti, anche in forma associata, che ci offrono i
                seguenti servizi:<br/>i. gestione tecnica del software dell&rsquo;APP<br/>ii. hosting dei dati<br/>iii.
                gestione delle comunicazioni con te tramite il canale e-mail<br/>iv. gestione della nostra
                infrastruttura IT<br/>Tutti questi soggetti sono qualificati come Responsabili del trattamento dei dati
                e sono tenuti al rispetto di tutte le<br/>vigenti norme in materia di protezione dei dati
                personali.<br/>Puoi ottenere l&rsquo;elenco di questi soggetti contattandoci tramite uno dei canali
                indicati al punto II.1) Chi &egrave; il Titolare<br/>del trattamento di dati.<br/>&bull; II.6)
                Trasferimento dei dati all&rsquo;estero<br/>I tuoi dati non saranno trasferiti in paesi terzi o a
                organizzazioni internazionali<br/>&bull; II.7) Per quanto tempo tratteremo i tuoi dati<br/>I tuoi dati
                saranno conservati nei nostri archivi per tempistiche diverse a seconda del motivo per cui stiamo<br/>effettuando
                il trattamento.<br/>i. Dati identificativi e di contatto: continueranno ad essere trattati fino a che
                non chiederai la loro<br/>cancellazione. In seguito a tale operazione non potremo pi&ugrave; entrare in
                contatto con te.</p>
            <p>ii. Tua immagine: continuer&agrave; ad essere trattata fino a che non la cancellerai<br/>iii. Dati per la
                gestione dei buoni sconto: saranno trattati per ventiquattro mesi dalla loro acquisizione.<br/>iv. Dati
                utilizzati per la profilazione e il marketing: saranno trattati per ventiquattro mesi dalla loro
                acquisizione,<br/>a meno che tu non ci chieda di cancellarli prima di tale scadenza.<br/>v. Dati
                relativi all&rsquo;utilizzo dell&rsquo;APP: saranno trattati per dodici mesi successivi alla loro
                raccolta, a meno che tu<br/>non ci chieda di cancellarli prima di tale scadenza.<br/>L&rsquo;Immagine
                dello scontrino di acquisto che hai caricato sull&rsquo;APP verr&agrave; cancellata:<br/>a) subito dopo
                la giocata del &ldquo;Gratta e vinci&rdquo;, nel caso tu non abbia vinto;<br/>b) subito dopo le
                verifiche da noi fatte, nel caso l&rsquo;immagine non sia valida o sia in contrasto con il<br/>regolamento
                di utilizzo dell&rsquo;APP<br/>c) dodici mesi dopo l&rsquo;utilizzo del buono presso l&rsquo;esercizio
                commerciale da scelto<br/>Al termine del periodo di conservazione<br/>i. i dati che ci permettono di
                identificarti l'interessato saranno resi anonimi<br/>ii. i dati che ci permettono di contattarti saranno
                cancellati fisicamente dal database<br/>iii. i dati relativi alla gestione dei buoni sconto saranno resi
                anonmi<br/>iv. i dati relativi alla profilazione verranno resi anonimi e il loro trattamento
                proseguir&agrave; a tempo indeterminato<br/>esclusivamente a fini statistici<br/>&bull; II.8) I tuoi
                diritti e le modalit&agrave; per esercitarli<br/>In ogni momento hai il diritto ad accedere ai tuoi dati
                personali, a richiedere che gli stessi vengano rettificati o<br/>cancellati, a opporti al trattamento
                degli stessi, a richiedere una limitazione del trattamento, a ricevere i tuoi dati<br/>personali in un
                formato strutturato e standard.<br/>A causa della specificit&agrave; dell&rsquo;APP
                non &egrave; prevista la possibilit&agrave; che tu possa richiedere la portabilit&agrave; verso
                altro<br/>soggetto.<br/>Ti segnaliamo che hai il diritto di revocare il consenso in qualsiasi momento
                senza pregiudicare la liceit&agrave; del<br/>trattamento basata sul consenso prestato prima della
                revoca.<br/>Per esercitare i tuoi diritti &egrave; sufficiente inviare una richiesta tramite uno dei
                canali indicati al punto II.1) Chi &egrave; il<br/>Titolare del trattamento dei dati.<br/>Se ritieni che
                i tuoi diritti siano stati violati o che abbiamo messo in atto un trattamento scorretto dei tuoi
                dati<br/>personali, hai diritto di rivolgerti al Garante della Privacy, che opera in qualit&agrave; di
                autorit&agrave; di controllo per il territorio<br/>italiano. Puoi contattare il Garante tramite il
                modulo che trovi sul sito www.garanteprivacy.it<br/>&bull; II.9) Quali dati ci devi comunicare
                obbligatoriamente e cosa capita se non lo fai<br/>Alcuni dati sono necessari per permetterti
                l&rsquo;utilizzo dell&rsquo;APP. Si tratta dei dati segnati con * o con # nell&rsquo;elenco che<br/>trovi
                al punto II.3) Quali dati trattiamo. Non c&rsquo;&egrave; alcun obbligo da parte tua di comunicarci
                questi dati, ma la<br/>mancanza di anche solo di uno di essi ci impedir&agrave; di farti utilizzare
                l&rsquo;APP.<br/>La comunicazione dei restanti dati &egrave; facoltativa. Puoi decidere liberamente
                quali siano i dati che intendi<br/>comunicarci. Nel caso in cui tu decida di non comunicare alcuni dati
                o di negare il successivo trattamento di uno o<br/>pi&ugrave; di questi dati, potrai continuare ad
                utilizzare l&rsquo;APP, ma noi non saremo in grado di personalizzare le nostre<br/>offerte in base alle
                tue preferenze ed interessi e/o non potrai mostrare la tua foto profilo.<br/>&bull; II.10 Esistenza di
                un processo decisionale automatizzato<br/>Qualora tu fornisca il tuo consenso, potremo utilizzare i
                dati, che ci hai comunicato o che raccogliamo mentre usi<br/>l&rsquo;APP, per realizzare
                attivit&agrave; di profilazione finalizzate a proporti prodotti e servizi in linea con i tuoi interessi
                e con le<br/>tue precedenti esperienze di acquisto.<br/>Al punto II.3) Quali dati trattiamo trovi
                l&rsquo;elenco completo dei dati che utilizziamo per la profilazione.<br/>I tuoi dati sono elaborati da
                nostro personale, appositamente da noi autorizzato, tramite l&rsquo;impiego di strumenti<br/>elettronici.<br/>I
                profili sono ottenuti tramite l&rsquo;incrocio dei dati e sono utilizzati per l&rsquo;invio di
                informazioni o di proposte commerciali<br/>da parte nostra.<br/>La realizzazione dei profili non avviene
                sulla base del solo trattamento automatizzato, poich&eacute; &egrave; prevista un&rsquo;azione<br/>di
                revisione realizzata manualmente dal personale autorizzato.<br/>Il trattamento avviene sempre nel pieno
                rispetto dei principi di correttezza, liceit&agrave; e trasparenza e di tutela della<br/>riservatezza e
                dei tuoi diritti.<br/>Il trattamento di profilazione non produce alcun effetto giuridico e non incide in
                modo significativo sui tuoi diritti,<br/>sulle tue libert&agrave; e suoi tuoi legittimi interessi.<br/>Vercelli
                19/11/2019</p>
        </div>);
}

export default PrivacyCittadino;