import React, {forwardRef, useImperativeHandle, useState} from "react";
import "../componentStyle/DescrizioneVetrina.scoped.css";

const DescrizioneVetrina = forwardRef((props, ref) => {
    const [descrizioneAttivita, setDescrizioneAttivita] = useState(props.dati ? props.dati.descrizione : '');

    const handleDescrizioneAttivita = e => {
        setDescrizioneAttivita(() => e.target.value);
    };

    const validate = () => descrizioneAttivita.length;

    const getData = () => descrizioneAttivita;

    useImperativeHandle(ref, () => ({
        validate, getData
    }));

    return (<div className="container lefter">
            <h4 className="logo-blue">Descrizione</h4>

            <div className="row">
                <div className="col s12 input-styled">
                    <label htmlFor="descrizioneAttivita">Descrizione</label>
                    <span
                        className={descrizioneAttivita.length < 150 ? 'contatore' : 'contatore color-red'}>{descrizioneAttivita.length} / 150</span>
                    <textarea style={{height: "200px"}} id="descrizioneAttivita" type="text"
                              className="validate browser-default"
                              value={descrizioneAttivita}
                              onChange={handleDescrizioneAttivita}
                              maxLength="150"></textarea>
                </div>
            </div>
        </div>);
});

export default DescrizioneVetrina;