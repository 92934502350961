import React from "react";
import "../pagesStyle/Azioni.scoped.css";
import Config from "../config";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Azioni = () => {
    const utente = useSelector(state => state.user);
    const acquisizioneBuono = Config.acquisizioneBuono && utente.concorso.parametri.acquisizione_buono === "1";
    const tipologiaUtente = utente.tipologia;
    const caricaCartoncino = Config.caricaCartoncino;
    const caricaScontrino = Config.caricaScontrino;
    const cliccaVinci = Config.cliccaVinci;
    const navigate = useNavigate();

    return (
        <div className="azioni">
            <div className="container">
                {tipologiaUtente === 'ESERCENTE' && acquisizioneBuono &&
                    <div className={"card-azione"} onClick={() => navigate("/carica-buono")}>
                        <div className={"immagine-azione"}>
                            <img src={`${Config.subDirectory}/assets/acquisisci_buono.png`}  alt={"acquisisci"}/>
                        </div>
                        <div className="descrizione-azione">
                            <div className="centratore">
                                <h3>Acquisisci Buono</h3>
                                <p>Scansiona i codici dei tuoi clienti</p>
                            </div>
                        </div>
                    </div>
                }
                {caricaScontrino && <div className={"card-azione"} onClick={() => navigate("/carica-scontrino")}>
                    <div className={"immagine-azione"}>
                        <img src={`${Config.subDirectory}/assets/carica_scontrino.png`} alt={"carica scontrino"}/>
                    </div>
                    <div className="descrizione-azione">
                        <div className="centratore">
                            <h3>Carica Scontrino</h3>
                            <p>Carica lo scontrino per ottenere dei "Clicca e Vinci" Ascom</p>
                        </div>
                    </div>
                </div>}
                {caricaCartoncino &&
                    <div className={"card-azione"} onClick={() => navigate("/carica-cartoncino")}>
                        <div className={"immagine-azione"}>
                            <img src={`${Config.subDirectory}/assets/carica_buono.png`}  alt={"carica buono"}/>
                        </div>
                        <div className="descrizione-azione">
                            <div className="centratore">
                                <h3>Carica Buono</h3>
                                <p>Scansiona i codici dal cartoncino per ottenere il tuo buono Ascom</p>
                            </div>
                        </div>
                    </div>
                }
                {cliccaVinci && <div className={"card-azione"} onClick={() => navigate("/gratta-vinci")}>
                    <div className={"immagine-azione"}>
                        <img src={`${Config.subDirectory}/assets/gratta_vinci.png`}  alt={"gratta vinci"}/>
                    </div>
                    <div className="descrizione-azione">
                        <div className="centratore">
                            <h3>Gioca con i "Clicca e Vinci"</h3>
                            <p>Clicca e scopri se hai vinto i buoni Ascom</p>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    );
}

export default Azioni;