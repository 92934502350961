import React from 'react'
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Home from "./pages/Home";
import Registrazione from "./pages/Registrazione";
import 'material-icons/iconfont/material-icons.css'
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min.js'
import './App.css';
import {useSelector} from "react-redux";
import CreaVetrina from "./pages/CreaVetrina";
import Vetrina from "./pages/Vetrina";
import CaricaScontrino from "./pages/CaricaScontrino";
import Completa from "./pages/Completa";
import Mappa from "./pages/Mappa";
import Config from "./config";
import NavigationTab from "./components/NavigationTab";
import HaiOttenuto from "./pages/HaiOttenuto";
import Azioni from "./pages/Azioni";
import GrattaVinci from "./pages/GrattaVinci";
import SingoloGrattaVinci from "./pages/SingoloGrattaVinci";
import Locali from "./pages/Locali";
import VistaVetrina from "./pages/VistaVetrina";
import Profilo from "./pages/Profilo";
import Recupera from "./pages/Recupera";
import RecuperaPassword from "./pages/RecuperaPassword.jsx";
import ModificaPassword from "./pages/ModificaPassword";
import ModificaDati from "./pages/ModificaDati";
import ModificaVetrina from "./pages/ModificaVetrina";
import Concorsi from "./pages/Concorsi";
import Portafogli from "./pages/Portafogli";
import CaricaBuono from "./pages/CaricaBuono";


const RoutesList = ({loggedUser, userTipologia, singoloConcorso, acquisizioneBuono, azioniAttive}) => {
  const location = useLocation();

  return (

      <Routes location={location}>
        {Config.abilitaMappa &&
            <Route path={"/"} exact element={loggedUser ? <Mappa/> : <Home/>}/>
        }
        {!Config.abilitaMappa &&
            <Route path={"/"} exact element={loggedUser && azioniAttive ? <Azioni/> : <Home/>}/>
        }
        <Route path={"/carica-scontrino"} element={loggedUser ? <CaricaScontrino/> : <Navigate to="/" replace/>}/>
        <Route path={"/recupera"} element={!loggedUser ? <Recupera/> : <Home/>}/>
        <Route path={"/reset/:token"} element={!loggedUser ? <RecuperaPassword/> : <Navigate to="/" replace/>}/>
        <Route path={"/registrazione"} element={!loggedUser ? <Registrazione/> : <Navigate to="/" replace/>}/>
        <Route path={"/creavetrina"} element={!loggedUser ? <CreaVetrina/> : <Navigate to="/" replace/>}/>
        <Route path={"/vetrina"} element={!loggedUser ? <Vetrina/> : <Navigate to="/" replace/>}/>
        <Route path={"/completa"} element={!loggedUser ? <Completa/> : <Navigate to="/" replace/>}/>
        <Route path={"/azioni"} element={loggedUser ? <Azioni/> : <Navigate to="/" replace/>}/>
        <Route path={"/haiottenuto/:grattaVinci"}
               element={loggedUser ? <HaiOttenuto/> : <Navigate to="/" replace/>}/>
        <Route path={"/gioca/:idGratta"} element={loggedUser ? <SingoloGrattaVinci/> : <Navigate to="/" replace/>}/>
        <Route path={"/gratta-vinci"} element={loggedUser ? <GrattaVinci/> : <Navigate to="/" replace/>}/>
        <Route path={"/locali"} element={loggedUser ? <Locali/> : <Navigate to="/" replace/>}/>
        <Route path={"/vistavetrina/:idVetrina"}
               element={loggedUser ? <VistaVetrina/> : <Navigate to="/" replace/>}/>
        <Route path={"/profilo"} element={loggedUser ? <Profilo/> : <Home/>}/>
        <Route path={"/modifica-password"} element={loggedUser ? <ModificaPassword/> : <Navigate to="/" replace/>}/>
        <Route path={"/modifica-dati"} element={loggedUser ? <ModificaDati/> : <Navigate to="/" replace/>}/>
        <Route path={"/concorsi"} element={loggedUser && !singoloConcorso ? <Concorsi /> : <Navigate to={"/"} replace/>}/>
        <Route path={"/portafogli"} element={loggedUser ? <Portafogli /> : <Navigate to={"/"} replace/>}/>
        <Route path={"/carica-buono"} element={loggedUser && acquisizioneBuono && userTipologia === 'ESERCENTE' ? <CaricaBuono /> : <Navigate to={"/"} replace/>}/>
        <Route path={"/modifica-vetrina"}
               element={loggedUser && userTipologia === 'ESERCENTE' ? <ModificaVetrina/> :
                   <Navigate to={"/"} replace/>}/>
      </Routes>
  );
}

function App() {
  const user = useSelector(state => state.user);
    const acquisizioneBuono = user.concorso ? user.concorso.parametri.acquisizione_buono : "0";
    const acquisizioneBuonoEnabled = Config.acquisizioneBuono && acquisizioneBuono === "1";
    const azioniAttive = Config.caricaCartoncino || Config.caricaScontrino || Config.cliccaVinci || (user.tipologia === 'ESERCENTE' && acquisizioneBuonoEnabled);
  return (<Router basename={Config.subDirectory}>
    <RoutesList loggedUser={user.loggedIn} azioniAttive={azioniAttive} userTipologia={user.tipologia} singoloConcorso={Config.singoloConcorso} acquisizioneBuono={Config.acquisizioneBuono && (user.concorso.parametri ? user.concorso.parametri.acquisizione_buono === "1" : false)}/>
    {user.loggedIn && <NavigationTab/>}
  </Router>);
}

export default App;
