import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import QrReader from 'react-qr-scanner';
import {QrScanner} from '@yudiel/react-qr-scanner';

const BarcodeScanner = forwardRef((props, ref) => {
    const [stream, setStream] = useState(null);

    const onError = error => {
        console.error(error);
        alert(error)
    }

    const onScan = data => {
        if (data) {
            props.sendResult(data.text);
        }
    }

    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        }
    };

    useImperativeHandle(ref, () => ({
        stopCamera
    }));

    const initCamera = async deviceId => {
        try {
            let mediaStream = false;
            if (deviceId === 'normale') {
                mediaStream = await window.navigator.mediaDevices.getUserMedia({video: {
                    facingMode: {
                        exact: 'environment'
                    }
                }});
            } else {
                mediaStream = await window.navigator.mediaDevices.getUserMedia({video: { deviceId: deviceId }});
            }
            setStream(mediaStream);
        } catch (error) {
            onError(error);
        }
    };

    /*useEffect(() => {
        if (props.deviceId) {
            stopCamera();
            initCamera(props.deviceId);
            return () => {
                if (stream) {
                    stream.getTracks().forEach(track => track.stop());
                }
            }
        } else {
            stopCamera()
        }
    }, [props.deviceId]);*/

    return (<>
            {/*stream &&
                <QrReader
                    onScan={onScan}
                    onError={onError}
                    style={{width: '100%', height: '100%'}}
                />
            */}
            <QrReader
                onScan={onScan}
                onError={onError}
                style={{width: '100%', height: '100%'}}
                constraints={{video: { facingMode: 'environment'}}}
            />
        </>
    );
});

export default BarcodeScanner;