import React from 'react'
import {useNavigate} from "react-router-dom";
import "../pagesStyle/CreaVetrina.scoped.css";

const CreaVetrina = () => {
    const navigate = useNavigate();
    const gotoVetrina = () => {
        navigate('/vetrina');
    }

    return (<div className="container">

        <h4 className="logo-blue">Crea la tua vetrina</h4>

        <img src="assets/bazar.png" alt={"crea vetrina"}/>

        <p>Abbiamo bisogno di ancora qualche istante del tuo tempo per completare il tuo profilo</p>

        <div className="btn btn-fullwidth" onClick={gotoVetrina}>Iniziamo</div>
    </div>);
}

export default CreaVetrina;