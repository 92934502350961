import Province from '../utils/province';
import "../componentStyle/AnagraficaVetrina.scoped.css";
import React, {forwardRef, useImperativeHandle, useState} from "react";

const AnagraficaVetrina = forwardRef((props, ref) => {
    const [nomeAttivita, setNomeAttivita] = useState(props.dati ? props.dati.nome_attivita : '');
    const [ragioneSociale, setRagioneSociale] = useState(props.dati ? props.dati.ragione_sociale : '');
    const [partitaIva, setPartitaIva] = useState(props.dati ? props.dati.partita_iva : '');
    const [indirizzo, setIndirizzo] = useState(props.dati ? props.dati.indirizzo : '');
    const [numeroCivico, setNumeroCivico] = useState(props.dati ? props.dati.numero_civico : '');
    const [comune, setComune] = useState(props.dati ? props.dati.comune : '');
    const [provincia, setProvincia] = useState(props.dati ? props.dati.provincia : 'Vercelli');
    const [cap, setCap] = useState(props.dati ? props.dati.cap : '');
    const [telefono, setTelefono] = useState(props.dati ? props.dati.telefono : '');
    const [errors, setErrors] = useState([]);
    const [anagrafica, setAnagrafica] = useState({});

    const handlePartitaIva = e => setPartitaIva(() => e.target.value.replace(/[^0-9]/, ''));
    const handleNomeAttivita = e => setNomeAttivita(() => e.target.value);
    const handleRagioneSociale = e => setRagioneSociale(() => e.target.value);
    const handleIndirizzo = e => setIndirizzo(() => e.target.value);
    const handleNumeroCivico = e => setNumeroCivico(() => e.target.value);
    const handleComune = e => setComune(() => e.target.value);
    const handleProvincia = e => setProvincia(() => e.target.value);
    const handleCap = e => setCap(() => e.target.value);
    const handleTelefono = e => setTelefono(() => e.target.value);

    const unCamelCase = val => {
        const result = val.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const validate = () => {
        const errorsInternal = [];
        const anagrafica = {
            nomeAttivita, ragioneSociale, partitaIva, indirizzo, numeroCivico, comune, provincia, cap, telefono
        };
        setAnagrafica(() => anagrafica);
        for (let key in anagrafica) {
            if (!anagrafica[key]) {
                errorsInternal.push('Compila il campo ' + unCamelCase(key));
            }
        }
        if (!/^[0-9]{7,10}$/.test(anagrafica.telefono)) {
            errorsInternal.push('Inserisci un numero di telefono corretto, senza il prefisso');
        }
        if (!/^[0-9]{5}$/.test(anagrafica.cap)) {
            errorsInternal.push('Inserisci un cap corretto');
        }
        if (!/^[0-9]{11}$/.test(anagrafica.partitaIva)) {
            errorsInternal.push('Inserisci una partita IVA valida');
        }
        if (!/^[A-Za-z0-9\s+]+/.test(anagrafica.indirizzo)) {
            errorsInternal.push('Inserire un indirizzo civico valido');
        }
        setErrors(() => errorsInternal);
        return !errorsInternal.length;
    }

    const getData = () => anagrafica;

    useImperativeHandle(ref, () => ({
        validate, getData
    }));

    return (<div>
            <h4 className="logo-blue">Anagrafica</h4>

            <div className="row">
                <div className="col s12 input-styled">
                    <label htmlFor="nomeAttivita">Nome attività</label>
                    <input id="nomeAttivita" type="text" className="validate browser-default" value={nomeAttivita}
                           onChange={handleNomeAttivita}/>
                </div>
                <div className="col s12 input-styled">
                    <label htmlFor="ragioneSociale">Ragione sociale</label>
                    <input id="ragioneSociale" type="text" className="validate browser-default" value={ragioneSociale}
                           onChange={handleRagioneSociale}/>
                </div>
                <div className="col s12 input-styled">
                    <label htmlFor="partitaIva">Partita IVA</label>
                    <input id="partitaIva" type="text"
                           className="validate browser-default"
                           maxLength="11" value={partitaIva} onChange={handlePartitaIva}/>
                </div>
                <div className="col s9 input-styled">
                    <label htmlFor="indirizzo">Indirizzo</label>
                    <input id="indirizzo" type="text" className="validate browser-default" value={indirizzo}
                           onChange={handleIndirizzo}/>
                </div>
                <div className="col s3 input-styled">
                    <label htmlFor="numeroCivico">Num</label>
                    <input id="numeroCivico" type="text" className="validate browser-default" value={numeroCivico}
                           onChange={handleNumeroCivico}/>
                </div>
                <div className="col s12 input-styled">
                    <label htmlFor="comune">Comune</label>
                    <input id="comune" type="text" className="validate browser-default" value={comune}
                           onChange={handleComune}/>
                </div>
                <div className="col s9 input-styled">
                    <label htmlFor="provincia">Provincia</label>
                    <select id="provincia" className="validate browser-default" value={provincia}
                            onChange={handleProvincia}>
                        {Province.map((p, index) => (<option key={index} value={p}>{p}</option>))}
                    </select>
                </div>
                <div className="col s3 input-styled">
                    <label htmlFor="cap">Cap</label>
                    <input id="cap" type="text" className="validate browser-default" value={cap} onChange={handleCap}/>
                </div>
                <div className="col s12 input-styled">
                    <label htmlFor="telefono">Telefono</label>
                    <input id="telefono" type="text" className="validate browser-default" value={telefono}
                           onChange={handleTelefono}/>
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <ul>
                        {errors.map((e, index) => (<li key={index}>{e}</li>))}
                    </ul>
                </div>
            </div>
        </div>);
})

export default AnagraficaVetrina;