import axiosInstance, {defaultHeaders} from './axiosConfig';

export default {
    login(email, password) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    email,
                    password,
                    action: 'login'
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    checkExistance(email, concorso) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    action: 'checkExistance',
                    email,
                    concorso
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getConcorsi(token, idConcorso) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idConcorso,
                        action: 'getConcorsi'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    registra(dati) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    ...dati,
                    action: 'registra'
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    uploadFotoProfilo(immagineProfilo, emailUtente) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    image: immagineProfilo,
                    format: immagineProfilo.split(':')[1].split(';')[0],
                    action: 'uploadImmagineProfilo',
                    email: emailUtente
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    checkBuono (token, codice) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    action: 'checkBuono',
                    codice
                },{
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    updateVetrina (token, idVetrina, invioVetrina) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    action: 'updateVetrina',
                    idVetrina,
                    invioVetrina
                },{
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    resetPassword (email) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    action: 'resetPassword',
                    email
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getUtenteByToken(token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    action: 'getUtenteByToken'
                },{
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    uploadFile(idTabella, file, dir, dbTable, dbField) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    idTabella,
                    dbTable,
                    dir,
                    dbField,
                    action: 'uploadFile',
                    image: file,
                    format: file.split(':')[1].split(';')[0],
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    uploadFileWithToken(token, file, dir, dbTable, dbField) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    dbTable,
                    dir,
                    dbField,
                    action: 'uploadFileWithToken',
                    image: file,
                    format: file.split(':')[1].split(';')[0],
                }, {
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    uploadScontrino(idConcorso, idEsercente, importo, numeroScontrino, dataScontrino, token, file, tabella) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    idConcorso,
                    idEsercente,
                    importo,
                    numeroScontrino,
                    dataScontrino,
                    dir: tabella,
                    dbTable: tabella,
                    action: 'uploadScontrino',
                    image: file,
                    format: file.split(':')[1].split(';')[0],
                }, {
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getLocali(idConcorso, token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idConcorso,
                        action: 'getLocali'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getParametriConcorso(idConcorso, token, parametri) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idConcorso,
                        parametri,
                        action: 'getParametriConcorso'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    checkConcorso(idConcorso, token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idConcorso,
                        action: 'checkConcorso'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    checkBuoniTerminati(idConcorso, token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idConcorso,
                        action: 'checkBuoniTerminati'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getLocaliConcorso(idConcorso, token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idConcorso,
                        action: 'getLocaliConcorso'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    aggiornaVincite(token, vincite) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    vincite,
                    action: 'aggiornaVincite',
                }, {
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getGrattaVinci(token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        action: 'getGrattaVinci'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getSingoloGrattaVinci(token, idGratta) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idBuono: idGratta,
                        action: 'getSingoloGrattaVinci'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getDatiGrattaPerdente(token, idGratta) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idBuono: idGratta,
                        action: 'getDatiGrattaPerdente'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getDatiGratta(token, idGratta) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idBuono: idGratta,
                        action: 'getDatiGratta'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    setGrattato(token, idGratta) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    idGratta,
                    action: 'setGrattato',
                }, {
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    updateProfilo(token, nome, cognome) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    nome,
                    cognome,
                    action: 'updateProfilo',
                }, {
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    updatePassword(password, corrente, token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post("?api", {
                    password,
                    corrente,
                    action: 'updatePassword',
                }, {
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getVetrina(token, idVetrina) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        idVetrina,
                        action: 'getVetrina'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getBuoni(token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        action: 'getBuoni'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    },
    getBuoniAcquisiti(token) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get("?api", {
                    params: {
                        action: 'getBuoniAcquisiti'
                    },
                    headers: {
                        ...defaultHeaders,
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(resp => resolve(resp.data))
                .catch(error => {
                    console.error(error);
                })
        })
    }
}