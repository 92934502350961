import React, {useEffect, useRef, useState} from 'react'
import "../componentStyle/ImageCropper.scoped.css";
import Hammer from 'hammerjs';
import ImageLoader from "../utils/ImageLoader";

const ImageCropper = ({imageData, cropped, annulla, widthParam, heightParam}) => {

    const [testImg, setTestImg] = useState(false);
    const [orientation, setOrientation] = useState('portrait');
    const [testUrl, setTestUrl] = useState('');
    const [maskStyle, setMaskStyle] = useState({width: "300px", height: "300px"});
    const imageref = useRef(null);
    const draggable = useRef(null);

    const width = widthParam ? widthParam : 300,
        height = heightParam ? heightParam : 300;

    useEffect(() => {
        if (imageData) {
            if (imageref.current) {
                imageref.current.style.top = 0;
                imageref.current.style.left = 0;
            }
            ImageLoader
                .load(imageData)
                .then(img => {
                    setTestImg(() => img);
                    if (img.width >= img.height) {
                        setOrientation(() => width <= height ? 'landscape' : 'portrait');
                    } else {
                        setOrientation(() => width < height ? 'landscape' : 'portrait');
                    }
                    ImageLoader
                        .resize(imageData, width, orientation !== 'landscape')
                        .then(dataUrl => {
                            setTestUrl(() => dataUrl);
                            ImageLoader
                                .load(dataUrl)
                                .then(img2 => {
                                    setTestImg(() => img2);
                                    startHammer();
                                });
                        });
                });
        }
    }, [imageData]);

    useEffect(() => {
        setMaskStyle(() => {
            return {width: width + "px", height: height + "px"}
        });
    }, [height, width]);

    const pxToNum = val => parseInt(val.replace('px', ''));

    const numToPx = val => val + "px";

    const getTrueLeft = (val, imgStyle) => {
        const imgWidth = pxToNum(imgStyle.width), minLeft = (width - imgWidth);
        let ret = val;

        if (val > 0) {
            ret = 0;
        }

        if (val < minLeft) {
            ret = minLeft;
        }

        return numToPx(ret);
    }

    const getTrueTop = (val, imgStyle) => {
        const imgHeight = pxToNum(imgStyle.height), minTop = (height - imgHeight);
        let ret = val;

        if (val > 0) {
            ret = 0;
        }

        if (val < minTop) {
            ret = minTop;
        }

        return numToPx(ret);
    }

    const cropImage = () => {
        const style = getComputedStyle(imageref.current), topStyle = pxToNum(style.top),
            leftStyle = pxToNum(style.left);

        ImageLoader
            .crop(testUrl, width, height, testImg.width, testImg.height, topStyle, leftStyle)
            .then(img => {
                cropped(img);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const startHammer = () => {
        const el = draggable.current, imgEl = imageref.current;
        let imgStyle = getComputedStyle(imgEl),
            hammerTime = new Hammer(el, {touchAction: 'none', direction: Hammer.DIRECTION_ALL}),
            startCenter = {x: 0, y: 0};

        hammerTime.on('panstart', () => {
            imgStyle = getComputedStyle(imgEl);

            startCenter = {
                x: pxToNum(imgStyle.left), y: pxToNum(imgStyle.top)
            };
        });

        hammerTime.on('panmove', (evt) => {
            imgEl.style.top = getTrueTop(startCenter.y + evt.deltaY, getComputedStyle(imageref.current));

            imgEl.style.left = getTrueLeft(startCenter.x + evt.deltaX, getComputedStyle(imageref.current));
        });

        hammerTime.get('pan').set({direction: Hammer.DIRECTION_ALL});
    }

    return (<div className="image-cropper">
            <span className="logo-blue">Trascina l'immagine nella<br/>posizione desiderata</span>

        <div style={{textAlign: 'center'}}>
            <div className="row btn-row">
                <div className="col">
                    <div className="btn" onClick={cropImage}>Salva</div>
                </div>
                <div className="col">
                    <div className="btn" onClick={annulla}>Annulla</div>
                </div>
            </div>
        </div>

        {imageData && <div className="cropper-container">
            <div className="square" style={maskStyle}>
                {testImg &&
                    <img src={testUrl} ref={imageref} className={orientation} id="imageref" alt={"immagine"}/>}
                <div className="mask" style={maskStyle} ref={draggable}></div>
                </div>
            </div>}
        </div>);
}

export default ImageCropper;