import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../components/Loader";
import "../pagesStyle/VistaVetrina.scoped.css";
import Config from "../config";
import DettagliAttivita from "../components/DettagliAttivita";
import dbService from "../utils/DbService";
import {useSelector} from "react-redux";

const VistaVetrina = () => {
    const [loading, setLoading] = useState(true);
    const [vetrina, setVetrina] = useState({});

    const {idVetrina} = useParams();
    const navigate = useNavigate();

    const token = useSelector(state => state.user.token);

    useEffect(() => {
        dbService
            .getVetrina(token, idVetrina)
            .then(resp => {
                setVetrina(resp);
                setLoading(false)
            })
    }, [])

    return (
        <div className="vetrina">
            {loading && <Loader />}

            {vetrina && <div className="relativo" style={{textAlign: 'center'}}>
                <div className="indietro" onClick={() => navigate(-1)}>
                    <img src={`${Config.subDirectory}/assets/indietro.png`} alt={"indietro"} />
                </div>

                <h1>{vetrina.nome_attivita}</h1>

                <div className="copertina" style={{backgroundImage: `url(${vetrina.copertina === 'PLACEHOLDER' ? `${Config.subDirectory}/assets/bazar.png` : Config.dbBaseUrl + vetrina.copertina}`}}></div>
                <div className="container">
                    <DettagliAttivita vetrina={vetrina} className="col s12 dettagli-attivita" />
                    <p>
                        {vetrina.descrizione}
                    </p>
                </div>
            </div>}
        </div>
    );
}

export default VistaVetrina;
