import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'userReducer', initialState: {
        loggedIn: false,
        loggedUser: null,
        nome: null,
        cognome: null,
        email: null,
        tipologia: null,
        propic: null,
        homeLoading: false,
        registrazione: {},
        concorso: false,
        vetrina: null,
        afterProfile: false,
        token: ''
    }, reducers: {
        afterProfile: (state) => {
            state.afterProfile = true;
        },

        login: (state, action) => {
            const {uid, nome, cognome, email, tipologia, fotoProfilo, vetrina, token} = action.payload;
            state.loggedIn = true;
            state.loggedUser = uid;
            state.nome = nome;
            state.cognome = cognome;
            state.email = email;
            state.tipologia = tipologia;
            state.propic = fotoProfilo;
            state.vetrina = vetrina;
            state.afterProfile = false;
            state.registrazione = {};
            state.token = token;
        },

        logout: (state) => {
            state.loggedIn = false;
            state.loggedUser = null;
            state.nome = null;
            state.cognome = null;
            state.email = null;
            state.tipologia = null;
            state.propic = null;
            state.homeLoading = false;
            state.concorso = false;
            state.vetrina = null;
            state.registrazione = {};
            state.afterProfile = false;
        },

        loading: (state, action) => {
            state.homeLoading = action.payload;
        },

        startRegister: (state, action) => {
            state.registrazione = action.payload;
            state.homeLoading = false;
        },

        endRegister: (state) => {
            state.registrazione = {};
            state.homeLoading = null;
        },

        registerStep1: (state, action) => {
            state.registrazione.tipologia = action.payload.tipologia;
            state.registrazione.fotoProfilo = action.payload.fotoProfilo;
        },

        registerStep2: (state, action) => {
            state.registrazione.vetrina = action.payload.vetrina
        },

        changePropic: (state, action) => {
            state.propic = action.payload;
        },

        updateProfile: (state, action) => {
            state.nome = action.payload.nome;
            state.cognome = action.payload.cognome;
        },

        updateVetrina: (state, action) => {
            state.vetrina = action.payload.vetrina;
        },

        updateCopertina: (state, action) => {
            state.vetrina.copertina = action.payload.copertina;
        },

        updateProfilo: (state, action) => {
          if (action.payload) {
              const {nome, cognome} = action.payload;
              return {
                  ...state,
                  nome,
                  cognome
              }
          }
        },

        setConcorso: (state, action) => {
            if (action.payload) {
                const { id, nome, mappa, data_fine, parametri } = action.payload;
                const jsonMappa = JSON.parse(mappa);
                state.concorso = { id, nome, mappa: { lat: jsonMappa.lat, lng: jsonMappa.lng }, dataFine: data_fine, parametri };
            } else {
                state.concorso = false;
            }
        },

        setRecuperoPassword: (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    recuperoPassword: true
                };
            }
        },

        removeRecuperoPassword: (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    recuperoPassword: false
                };
            }
        },

        updateVinciteConcorso: (state, action) => {
            if (action.payload) {
                const updatedVincite = action.payload.map((vincita, index) => {
                    const {vinti} = vincita;
                    return {
                        ...state.concorso.vincite[index],
                        vinti
                    }
                });
                state.concorso.vincite = updatedVincite;
            }
        },

        updatePropic: (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    propic: action.payload
                }
            }
        },

        setVinciteConcorso: (state, action) => {
            if (action.payload) {
                const vincite = [];
                action.payload.forEach(vincita => {
                    const {img_buono, img_gratta, percentuali, priorita, testo_header, testo_vinto, testo_vinto_gratta, tipo, totali, vinti, id_parametro} = vincita;
                    vincite.push({
                        idParametro: id_parametro,
                        imgBuono: img_buono,
                        imgGratta: img_gratta,
                        testoHeader: testo_header,
                        testoVinto: testo_vinto,
                        testoVintoGratta: testo_vinto_gratta,
                        percentuali: JSON.parse(percentuali),
                        tipo,
                        priorita,
                        totali,
                        vinti
                    })
                });
                state.concorso.vincite = vincite;
            } else {
                state.concorso.vincite = false;
            }
        },

        setParametriConcorso: (state, action) => {
            if (action.payload) {
                const {img_perso, regolamento_url, singola_vincita, sponsor_image, termini_url, ogni_quanti_euro_custom, ogni_quanti_euro, acquisizione_buono} = action.payload;
                const parametri = {};
                parametri.imgPerso = img_perso;
                parametri.regolamentoUrl = regolamento_url;
                parametri.singolaVincita = singola_vincita === "1";
                parametri.sponsorImage = sponsor_image;
                parametri.terminiUrl = termini_url;
                parametri.acquisizioneBuono = acquisizione_buono === "1";
                if (ogni_quanti_euro_custom != null) {
                    const ogniQuantiEuroCustom = JSON.parse(ogni_quanti_euro_custom);
                    parametri.ogniQuantiEuro = parseFloat(ogniQuantiEuroCustom.ogni_quanti_euro);
                    parametri.buoniMassimi = parseInt(ogniQuantiEuroCustom.buoniMassimi);
                    parametri.primoBuono = {
                        daEuro: parseFloat(ogniQuantiEuroCustom.primo_buono.da_euro),
                        aEuro: parseFloat(ogniQuantiEuroCustom.primo_buono.a_euro)
                    }
                } else {
                    parametri.ogniQuantiEuro = parseFloat(ogni_quanti_euro);
                }
                state.concorso.parametri = parametri;
            } else {
                state.concorso.parametri = false;
            }
        }
    },
});

export const {
    afterProfile,
    login,
    logout,
    loading,
    startRegister,
    endRegister,
    registerStep1,
    changePropic,
    updateProfile,
    updateVetrina,
    updateCopertina,
    setConcorso,
    registerStep2,
    setParametriConcorso,
    setVinciteConcorso,
    updateVinciteConcorso,
    updatePropic,
    setRecuperoPassword,
    removeRecuperoPassword,
    updateProfilo
} = userSlice.actions;

export default userSlice.reducer;