import React, {useEffect, useState} from "react";
import "../pagesStyle/Locali.scoped.css";
import SearchBar from "../components/SearchBar";
import Loader from "../components/Loader";
import {useNavigate} from "react-router-dom";
import Config from "../config";
import DettagliAttivita from "../components/DettagliAttivita";
import {useSelector} from "react-redux";
import dbService from "../utils/DbService";

const Locali = () => {
    const [loading, setLoading] = useState(true);
    const [localiFiltrati, setLocaliFiltrati] = useState([]);
    const [locali, setLocali] = useState([]);

    const navigate = useNavigate();

    const concorsoId = useSelector(state => state.user.concorso.id);
    const token = useSelector(state => state.user.token);

    const filtra = testo => {
        const tempLocali = [];
        locali.forEach(locale => {
            if (!testo || locale.nome_attivita.toLowerCase().indexOf(testo) !== -1) {
                tempLocali.push(locale);
            }
        })
        setLocaliFiltrati(() => tempLocali)
    }

    useEffect(() => {
        dbService.getLocali(concorsoId, token).then(resp => {
            setLocali(() => {
                setLoading(false);
                return resp;
            });
            setLocaliFiltrati(resp);
        });
    }, [concorsoId])

    return (
        <div>
            <SearchBar ricerca={filtra} />

            <div className="container">

                {loading && <Loader />}

                {!loading && localiFiltrati.map(locale => (
                    <div className={"locale row"}
                    key={locale.id}
                    onClick={() => navigate(`/vistavetrina/${locale.id}`)}>
                        <h1>{locale.nome_attivita}</h1>
                        <div className={"col s3 immagine"}>
                            <img src={locale.copertina !== 'PLACEHOLDER' ? Config.dbBaseUrl + locale.copertina : `${Config.subDirectory}/assets/bazar.png`} alt={"vetrina locale"} />
                        </div>
                        <DettagliAttivita vetrina={locale} vetrinaCorta={true}/>
                    </div>
                ))}
                {!loading && localiFiltrati.length === 0 && <div className={"no-locali"}>
                        Non vi sono locali che partecipano a questo concorso
                    </div>}
            </div>
        </div>
    )
}

export default Locali;