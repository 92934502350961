import React, {useEffect, useRef, useState} from "react";
import "../pagesStyle/Profilo.scoped.css";
import Loader from "../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Config from "../config";
import ImageCropper from "../components/ImageCropper";
import CordovaModal from "../components/CordovaModal";
import M from "materialize-css";
import ImageLoader from "../utils/ImageLoader";
import dbService from "../utils/DbService";
import {logout as actionLogout, updatePropic} from "../reducers/accountReducers";

const Profilo = () => {
    const [loading, setLoading] = useState(true);
    const [fotoProfilo, setFotoProfilo] = useState('');
    const [urlRegolamento, setUrlRegolamento] = useState('');
    const [cropImg, setCropImg] = useState('');
    const [acquisizioneBuono, setAcquisizioneBuono] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const uploadCordova = useRef(null);
    const modalCrop = useRef(null);
    const containerRef = useRef(null);

    const profilo = useSelector(state => state.user);
    const concorso = useSelector(state => state.user.concorso);
    const token = useSelector(state => state.user.token);

    const uploadProcess = e => {
        const file = containerRef.current.querySelector("#upload-foto-profilo").files[0];
        if (!file) {
            return true;
        }
        setCropImg(() => file);
        getModalCrop().open();
    }

    const fromCordova = b64 => {
        getModalCrop().open();
        setCropImg(() => b64);
    }

    const getModalCrop = () => M.Modal.getInstance(modalCrop.current);

    useEffect(() => {
        const elems = containerRef.current.querySelectorAll(".modal");
        M.Modal.init(elems, {
            dismissible: false
        });
        if (profilo.propic === 'PLACEHOLDER') {
            setFotoProfilo(`${Config.subDirectory}/assets/placeholder.png`);
            setLoading(false);
        } else {
            setFotoProfilo(Config.dbBaseUrl + profilo.propic);

            ImageLoader
                .load(Config.dbBaseUrl + profilo.propic)
                .then(() => {
                    setLoading(false);
                });
        }
        setUrlRegolamento(concorso.parametri ? concorso.parametri.regolamento_url : '')
        setAcquisizioneBuono(Config.acquisizioneBuono && (concorso.parametri ? concorso.parametri.acquisizione_buono === "1" : false));
    }, []);

    const logout = () => {
        dispatch(actionLogout());
        navigate("/");
    }

    const uploadProfilo = img => {
        setLoading(true);
        dbService
            .uploadFileWithToken(token, img, 'foto_profilo', 'utenti', 'foto_profilo')
            .then(resp => {
                const downloadURL = resp.url_image;
                setFotoProfilo(img);
                dispatch(updatePropic(downloadURL));
                setLoading(false);
                getModalCrop().close();
            });
    }

    const annulla = () => {
        getModalCrop().close();
    }

    return (
        <div className="profilo" ref={containerRef}>
            {loading && <Loader/>}

            <div className="container-foto-profilo">
                <div className="bordo-foto-profilo">
                    <input type="file" id="upload-foto-profilo" onChange={uploadProcess}
                           accept="image/x-png,image/gif,image/jpeg" capture={"environment"}/>
                    <img src={fotoProfilo} alt={"foto profilo"}/>

                    <div className="fotocamera">
                        <img src={`${Config.subDirectory}/assets/fotocamera_white.png`} alt={"fotocamera"}/>
                    </div>
                </div>
            </div>

            <div className="container profilo-main">
                {profilo.tipologia === 'STUDENTE' && <div className="uniupo">
                    <img src={`${Config.subDirectory}/assets/alumni.jpg`} alt={"alunno"}/>
                </div>}

                <h3>{profilo.nome} {profilo.cognome}</h3>

                <h4>{profilo.email}</h4>

                {profilo.tipologia === 'STUDENTE' && <div className="tipologia">{profilo.tipologia}</div>}

                {profilo.tipologia === 'STUDENTE' && <div className="tipologia">
                    Anno {new Date().getFullYear()}
                </div>}
                {concorso &&
                    <div className={"tipologia concorso"} onClick={() => navigate("/concorsi")}>
                        {concorso.nome}
                    </div>
                }

                <ul className="menu-profilo">
                    <li onClick={() => navigate("/modifica-dati")}>
                        <span>Modifica Dati</span>
                        <img src={`${Config.subDirectory}/assets/edit.png`} alt={"modifica"}/>
                    </li>

                    <li onClick={() => navigate("/modifica-password")}>
                        <span>Modifica password</span>
                        <img src={`${Config.subDirectory}/assets/edit.png`} alt={"modifica"}/>
                    </li>

                    {acquisizioneBuono &&
                        <li onClick={() => navigate("/portafogli")}>
                            <span>I miei Buoni</span>
                            <img src={`${Config.subDirectory}/assets/goon.png`} alt={"portafoglio"}/>
                        </li>
                    }

                    <li>
                        <a href={urlRegolamento} target="_BLANK" rel="noopener noreferrer">
                            <span>Regolamento</span>
                            <img src={`${Config.subDirectory}/assets/goon.png`} alt={"regolamento"}/>
                        </a>
                    </li>

                    {!Config.singoloConcorso &&
                        <li onClick={() => navigate("/concorsi")}>
                            <span>Seleziona concorso</span>
                            <img src={`${Config.subDirectory}/assets/goon.png`} alt={"concorsi"}/>
                        </li>
                    }

                    <li>
                        <a href="mailto:info@vivichivasso.it" target="_BLANK" rel="noopener noreferrer">
                            <span>Assistenza</span>
                        </a>
                    </li>

                    <li onClick={() => logout()}>
                        <span>Esci</span>
                    </li>
                </ul>
            </div>

            <div id="crop-modal" ref={modalCrop} className="modal">
                <ImageCropper
                    widthParam={200}
                    heightParam={200}
                    imageData={cropImg}
                    cropped={uploadProfilo}
                    annulla={annulla}/>
            </div>

            <CordovaModal ref={uploadCordova} upload={fromCordova}/>
        </div>
    );
}

export default Profilo;
