import axios from 'axios';
import Config from '../config';

const axiosConfig = {
    baseURL: Config.dbBaseUrl, timeout: 5000, headers: {
        'Content-Type': 'application/json'
    }
};

const axiosInstance = axios.create(axiosConfig);

export const defaultHeaders = axiosInstance.defaults.headers.common;
export default axiosInstance;