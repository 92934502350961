import React, {useEffect, useRef, useState} from "react";
import "../componentStyle/Orario.scoped.css";
import M from 'materialize-css';

const Orario = ({options, elimina, modificaOrario}) => {
    const [dati, setDati] = useState({
        id: options.id,
        giorni: options.giorni,
        continuato: options.continuato,
        inizio_1: options.inizio_1,
        inizio_2: options.inizio_2,
        fine_1: options.fine_1,
        fine_2: options.fine_2
    });
    const giorniSettimana = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];
    const container = useRef(null);

    const handleDati = (id, value) => {
        const tempDati = dati;
        tempDati[`${id}`] = value;
        setDati(prevDati => {
            modificaOrario(tempDati.id, tempDati);
            return {
                ...prevDati, [`${id}`]: tempDati[`${id}`]
            };
        })
    }

    const handleContinuato = () => {
        const continuato = !dati.continuato;
        const tempDati = {
            ...dati, continuato
        };
        modificaOrario(tempDati.id, tempDati);
        setDati(prevDati => {
            return {
                ...prevDati, ...tempDati
            }
        })
    }

    const toggleDay = day => {
        const positionDay = dati.giorni.indexOf(day);
        let datiTemp = dati;
        const tempGiorni = datiTemp.giorni;
        if (positionDay === -1) {
            tempGiorni.push(day);
        } else {
            tempGiorni.splice(positionDay, 1);
        }
        datiTemp = {
            ...datiTemp, giorni: tempGiorni
        };
        modificaOrario(datiTemp.id, datiTemp);
        setDati(prevDati => {
            return {
                ...prevDati, ...datiTemp
            };
        });
    }

    useEffect(() => {
        const elems = container.current.querySelectorAll(".timepicker");
        M.Timepicker.init(elems, {
            twelveHour: false, autoClose: true, onCloseEnd: function () {
                const id = this.$el[0].id;
                const value = this.$el[0].value;
                handleDati(id, value);
            }
        });
    }, [])

    return (<div className="orario-container" ref={container}>
            <div className="row">
                <div className="col s12">
                    <div className="flexer-ailati">
                        <span>Seleziona i giorni con lo stesso orario</span>
                        <span className="elimina" onClick={() => elimina(dati.id)}>Elimina</span>
                    </div>
                    <div className="giorni">
                        {giorniSettimana.map((g, index) => (<div
                                className={dati.giorni.indexOf(g) === -1 ? 'giorno' : 'giorno attivo'}
                                key={g}
                                onClick={() => toggleDay(g)}
                            >{g}</div>))}
                    </div>
                </div>

                <div className="col s3 input-styled">
                    <label htmlFor="inizio_1">Inizio</label>
                    <input id="inizio_1" type="text" className="validate browser-default timepicker" readOnly={true}
                           value={dati.inizio_1}/>
                </div>

                <div className="col s3 input-styled">
                    <label htmlFor="fine_1">Fine</label>
                    <input id="fine_1" type="text" className="validate browser-default timepicker" readOnly={true}
                           value={dati.fine_1}/>
                </div>

                <div className="col s6 input-field paddato">
                    <label>
                        <input type="checkbox"
                               checked={dati.continuato}
                               onClick={handleContinuato}/>
                        <span>Orario continuato</span>
                    </label>
                </div>

                <div className="clearfix"></div>

                <div className="col s3 input-styled" style={dati.continuato ? {display: "none"} : {}}>
                    <label htmlFor="inizio_2">Inizio</label>
                    <input id="inizio_2" type="text" className="validate browser-default timepicker" readOnly={true}
                           value={dati.inizio_2}/>
                </div>

                <div className="col s3 input-styled" style={dati.continuato ? {display: "none"} : {}}>
                    <label htmlFor="fine_2">Fine</label>
                    <input id="fine_2" type="text" className="validate browser-default timepicker" readOnly={true}
                           value={dati.fine_2}
                    />
                </div>
            </div>
        </div>);
}

export default Orario;