import M from 'materialize-css';
import React, {forwardRef, useEffect, useRef} from "react";

const CordovaModal = forwardRef((props, ref) => {
    const options = {
        destinationType: 0,
        quality: 50,
        encodingType: 0
    };
    const upload = props.upload;
    const modale = useRef(null);
    const scattaFoto = () => {
        navigator.camera.getPicture(photo => {
            getModal().close();
            upload('data:image/jpeg;base64,' + photo);
        }, err => {
            console.log(err);
        }, options);
    };

    const getModal = () => {
        return M.Modal.getInstance(modale.current);
    };

    const galleria = () => {
        navigator.camera.getPicture((photo) => {
            getModal().close();
            upload('data:image/jpeg;base64,' + photo);
        }, err => {
            console.log(err);
        }, {
            ...options,
            sourceType: 0
        });
    };

    useEffect(() => {
        const elems = modale.current;
        M.Modal.init(elems, {});
    });

    return (
        <div className="modal" id="file-modal" ref={modale}>
            <div className="modal-content">
                <button className="btn waves-effect waves-light" onClick={scattaFoto}> Scatta una foto</button>
                <br/><br/>
                <button className="btn waves-effect waves-light" onClick={galleria}> Scegli dalla galleria</button>
            </div>
        </div>
    );
})

export default CordovaModal;