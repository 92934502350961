import Config from "../config";

const baseUrl = Config.geoCodeBaseUrl;

const baseLatLong = [45.32022720000001, 8.418573499999999];

const fromAddress = address => {
    const requestOptions = {
        method: 'GET', redirect: 'follow'
    };

    return new Promise(resolve => {
        if (!Config.enableGeocodeLatLong) {
            resolve({
                location: {
                    lat: baseLatLong[0], long: baseLatLong[1]
                }
            });
        }
        fetch(baseUrl + "?format=geojson&q=" + encodeURIComponent(address), requestOptions)
            .then(response => {
                const JsonResponse = response;
                const presentCoordinates = JsonResponse && JsonResponse.features && JsonResponse.features[0] && JsonResponse.features[0].geometry && JsonResponse.features[0].geometry.coordinates;
                const lat = presentCoordinates ? JsonResponse.features[0].geometry.coordinates[0] : baseLatLong[0];
                const long = presentCoordinates ? JsonResponse.features[0].geometry.coordinates[1] : baseLatLong[1];
                resolve({
                    location: {
                        lat, long
                    }
                })
            })
    })

}

export {fromAddress};


