import React from "react";
import Config from "../config";
const DettagliAttivita = ({vetrinaCorta, vetrina}) => {
    return (
        <div className={'col s9 dettagli-attivita'}>
            <img src={`${Config.subDirectory}/assets/cartellino.png`}  alt={"vetrina indirizzo"}/>{vetrina.indirizzo} {vetrina.numero_civico}, {vetrina.comune}<br />
            <img src={`${Config.subDirectory}/assets/telefonino.png`}  alt={"vetrina telefono"}/>{vetrina.telefono}<br />
            {vetrinaCorta &&
                vetrina.orari.map((item, key) => (
                    <div key={key}>
                        {item.continuato &&
                            <span>
                                {item.giorni.split(",").map((giorno, key2) => (<span key={key2}>{giorno}&nbsp;</span>))}<br/>
                                <img src={`${Config.subDirectory}/assets/orologino.png`} alt={"orario"}/>{item.inizio_1} - {item.fine_1}
                            </span>
                        }
                        {!item.continuato &&
                            <span>
                                {item.giorni.split(",").map((giorno, key2) => (<span key={key2}>{giorno}&nbsp;</span>))}<br/>
                                <img src={`${Config.subDirectory}/assets/orologino.png`} alt={"orario"}/>{item.inizio_1} - {item.fine_1}
                                <img src={`${Config.subDirectory}/assets/orologino.png`} alt={"orario"}/>{item.inizio_2} - {item.fine_2}
                            </span>
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default DettagliAttivita;