import React, {useEffect, useRef, useState} from "react";
import "../pagesStyle/ModificaVetrina.scoped.css";
import LoaderPercent from "../components/LoaderPercent";
import Loader from "../components/Loader";
import AnagraficaVetrina from "../components/AnagraficaVetrina";
import {useDispatch, useSelector} from "react-redux";
import OrarioVetrina from "../components/OrarioVetrina";
import DescrizioneVetrina from "../components/DescrizioneVetrina";
import ImmaginiVetrina from "../components/ImmaginiVetrina";
import BottomBarModificaVetrina from "../components/BottomBarModificaVetrina";
import {useNavigate} from "react-router-dom";
import M from "materialize-css";
import {fromAddress} from "../utils/geocode";
import {updateVetrina} from "../reducers/accountReducers";
import dbService from "../utils/DbService";

const ModificaVetrina = () => {
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(1);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tot = 4;

    const dati = useSelector(state => state.user.vetrina);
    const token = useSelector(state => state.user.token);
    const anagraficaRef = useRef(null);
    const orariRef = useRef(null);
    const descrizioneRef = useRef(null);
    const immaginiRef = useRef(null);

    const geocodeAddress = anagrafica => {
        const promessa = new Promise(resolve => {
            fromAddress(
                anagrafica.indirizzo + ' ' + anagrafica.numeroCivico + ' ' +
                anagrafica.comune + ' ' +
                anagrafica.provincia + ' ' + anagrafica.cap + ' ' +
                'Italia'
            ).then(result => {
                resolve(result);
            })
        });
        return promessa;
    }

    const uploadCopertina = (idTabella, copertina) => {
        return uploadFile(idTabella, copertina, 'copertina', 'vetrina', 'copertina');
    }

    const uploadFile = async (idTabella, file, dir, dbTable, dbField) => {
        return await dbService.uploadFile(idTabella, file, dir, dbTable, dbField);
    }

    const modificaCommerciante = () => {
        const anagrafica = anagraficaRef.current.getData(),
            orari = orariRef.current.getData(),
            descrizione = descrizioneRef.current.getData(),
            immagini = immaginiRef.current.getData(),
            idVetrina = dati.id,
            vecchiaCopertina = immagini.copertina.startsWith('http');

        geocodeAddress(anagrafica).then(async response => {
            const geometry = response.location;
            anagrafica.nomeAttivita = anagrafica.nomeAttivita.charAt(0).toUpperCase() + anagrafica.nomeAttivita.slice(1);
            anagrafica.latLong = [geometry.lat, geometry.long];
            const invioVetrina = {
                anagrafica,
                descrizione,
                orari
            };
            if (!vecchiaCopertina) {
                invioVetrina.copertina = 'PLACEHOLDER'
            }
            dbService
                .updateVetrina(token, idVetrina, invioVetrina)
                .then(async resp => {
                    setLoading(false);
                    if (resp.status === 'OK') {
                        M.toast({ html: 'Vetrina modificata con successo' });
                        if (!vecchiaCopertina && immagini.copertina) {
                            const updatedCopertina = await uploadCopertina(idVetrina, immagini.copertina);
                            resp.vetrina.copertina = updatedCopertina.url_image;
                        }
                        dispatch(updateVetrina(resp));
                        setTimeout(() => {
                            navigate("/profilo");
                        }, 1000);
                    } else {
                        M.toast({ html: 'Errore durante la modifica della vetrina' });
                    }
                })
        });
    }

    const validate = () => {
        let resp;
        switch (current) {
            case 1:
                resp = anagraficaRef.current.validate();

                if (!resp) {
                    M.toast({ html: 'Completa correttamente i campi' });
                    return false;
                }

                forward();
                break;
            case 2:
                resp = orariRef.current.validate();

                if (!resp) {
                    M.toast({ html: 'Completa gli orari con tutte le informazioni' });
                    return false;
                }

                forward();
                break;
            case 3:
                resp = descrizioneRef.current.validate();

                if (!resp) {
                    M.toast({ html: 'Aggiungi una breve descrizione' });
                    return false;
                }

                forward();
                break;
            case 4:
                setLoading(() => true);
                modificaCommerciante();
                break;
            default:
                M.toast({ html: 'Qualcosa non ha funzionato' });
                break;
        }
    }

    const toProfile = () => {
        navigate("/profilo");
    }

    const backward = () => {
        if (current > 1) {
            setCurrent(prev => prev - 1);
        }
    }

    const forward = () => {
        if (current < tot) {
            setCurrent(prev => prev + 1);
        }
    }

    useEffect(() => {
        setPercent(() => 100 * current / tot);
    }, [current]);

    return (
        <div className="registrazione">
            <LoaderPercent percent={percent} />

            {loading && <Loader />}

            <div className="page-container">
                <div className={current === 1 ? 'page active' : 'page'} data-id={"1"}>
                    <AnagraficaVetrina ref={anagraficaRef} dati={dati} />
                </div>

                <div className={current === 2 ? 'page active' : 'page'} data-id={"2"}>
                    <OrarioVetrina ref={orariRef} dati={dati} />
                </div>

                <div className={current === 3 ? 'page active' : 'page'} data-id={"3"}>
                    <DescrizioneVetrina ref={descrizioneRef} dati={dati} />
                </div>

                <div className={current === 4 ? 'page active' : 'page'} data-id={"4"}>
                    <ImmaginiVetrina ref={immaginiRef} dati={dati} />
                </div>

                <BottomBarModificaVetrina forward={validate} toProfile={toProfile} backward={backward} tot={tot} current={current} />

        </div>
</div>
    );
}

export default ModificaVetrina;