import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "../componentStyle/NavigationTab.scoped.css";
import {useSelector} from "react-redux";
import Config from "../config";

const NavigationTab = () => {
    const location = useLocation();
    const navigation = useNavigate();
    const path = location.pathname;
    const [acquisizioneBuono, setAcquisizioneBuono] = useState(false);
    const concorso = useSelector(state => state.user.concorso);
    const utente = useSelector(state => state.user);
    const acquisizioneBuonoEnabled = Config.acquisizioneBuono && utente.concorso.parametri.acquisizione_buono === "1";
    const azioniAttive = Config.caricaCartoncino || Config.caricaScontrino || Config.cliccaVinci || (utente.tipologia === 'ESERCENTE' && acquisizioneBuonoEnabled);

    useEffect(() => {
        setAcquisizioneBuono(Config.acquisizioneBuono && (concorso.parametri ? concorso.parametri.acquisizione_buono === "1" : false));
    });

    if (path !== "/concorsi") {
        return (
            <div className={"bottom-nav"}>
                <div className={"nav-link"} onClick={() => navigation("/locali")}>
                    {path !== "/locali" && <img src={`${Config.subDirectory}/assets/2_bandierina.png`} alt={"locale"}/>}
                    {path === "/locali" && <img src={`${Config.subDirectory}/assets/2_bandierina_selected.png`} alt={"locale selezionato"}/>}
                </div>
                {azioniAttive && <div className={"nav-link"} onClick={() => navigation("/azioni")}>
                    {path !== "/azioni" && <img src={`${Config.subDirectory}/assets/3_azioni.png`} alt={"azione"}/>}
                    {path === "/azioni" && <img src={`${Config.subDirectory}/assets/3_azioni_selected.png`} alt={"azione selezionata"}/>}
                </div>}
                {acquisizioneBuono && <div className={"nav-link"} onClick={() => navigation("/portafogli")}>
                    {path !== "/portafogli" && <img src={`${Config.subDirectory}/assets/4_portafogli.png`} alt={"buoni acquisiti"}/>}
                    {path === "/portafogli" && <img src={`${Config.subDirectory}/assets/4_portafogli_selected.png`} alt={"buoni acquisiti selezionati"}/>}
                </div>}
                <div className={"nav-link"} onClick={() => navigation("/profilo")}>
                    {path !== "/profilo" && <img src={`${Config.subDirectory}/assets/placeholder.png`} alt={"profilo"}/>}
                    {path === "/profilo" && <img src={`${Config.subDirectory}/assets/placeholder.png`} alt={"profilo selezionato"}/>}
                </div>
            </div>
        )
    }
    return (<></>);
}

export default NavigationTab;