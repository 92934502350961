import React from "react";
import "../pagesStyle/Completa.scoped.css";
import {endRegister} from "../reducers/accountReducers";
import {useDispatch} from "react-redux";
import Config from "../config";
import {useNavigate} from "react-router-dom";

const Completa = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gotoHome = () => {
        dispatch(endRegister({}));
        navigate("/");
    }

    return (<div className="container">

            <h4 className="logo-blue">Abbiamo finito</h4>

            <img src={`${Config.subDirectory}/assets/bazar.png`} alt={"vetrina"}/>

            <p>Grazie! Non appena Ascom abiliter&agrave; il tuo account potrai accedere</p>

            <div className="btn btn-fullwidth" onClick={gotoHome}>Torna in home</div>
        </div>);
}

export default Completa;