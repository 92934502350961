import LoaderPercent from "../components/LoaderPercent";
import Loader from "../components/Loader";
import {useEffect, useRef, useState} from "react";
import BottomBar from "../components/BottomBar";
import M from 'materialize-css';
import {useDispatch, useSelector} from "react-redux";
import {fromAddress} from "../utils/geocode";
import AnagraficaVetrina from "../components/AnagraficaVetrina";
import OrarioVetrina from "../components/OrarioVetrina";
import DescrizioneVetrina from "../components/DescrizioneVetrina";
import ImmaginiVetrina from "../components/ImmaginiVetrina";
import "../pagesStyle/Vetrina.scoped.css";
import {registerStep2} from "../reducers/accountReducers";
import React from 'react'
import dbService from "../utils/DbService";
import {useNavigate} from "react-router-dom";


const Vetrina = () => {
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(1);

    const anagraficaRef = useRef(null);
    const orariRef = useRef(null);
    const descrizioneRef = useRef(null);
    const immaginiRef = useRef(null);
    const step1 = useSelector(state => state.user.registrazione);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tot = 4;

    useEffect(() => {
        setPercent(() => 100 * current / tot);
    }, [current]);

    const registerCommerciante = () => {
        const anagrafica = anagraficaRef.current.getData(),
            orari = orariRef.current.getData(),
            descrizione = descrizioneRef.current.getData(),
            immagini = immaginiRef.current.getData();

        geocodeAddress(anagrafica).then(async response => {
            const geometry = response.location;
            anagrafica.nomeAttivita = anagrafica.nomeAttivita.charAt(0).toUpperCase() + anagrafica.nomeAttivita.slice(1);
            anagrafica.latLong = [geometry.lat, geometry.long];
            dispatch(registerStep2({
                vetrina: {
                    anagrafica,
                    descrizione,
                    orari,
                    copertina: 'PLACEHOLDER'
                }
            }));
            dbService
                .registra({
                    tipologia: 'ESERCENTE',
                    nome: step1.nome,
                    cognome: step1.cognome,
                    email: step1.email,
                    fotoProfilo: 'PLACEHOLDER',
                    consenso: false,
                    abilitato: true,
                    password: step1.password,
                    vetrina: {
                        anagrafica,
                        descrizione,
                        orari,
                        copertina: 'PLACEHOLDER'
                    }
                })
                .then(async resp => {
                    if (resp.status === 'OK') {
                        const idUtente = resp.uid;
                        const idVetrina = resp.vid;
                        if (step1.fotoProfilo !== 'PLACEHOLDER') {
                            await uploadPropic(idUtente, step1.fotoProfilo);
                        }
                        if (immagini.copertina) {
                            await uploadCopertina(idVetrina, immagini.copertina);
                        }
                        if (immagini.galleria.length) {
                            for (const file of immagini.galleria) {
                                await uploadImmagineGalleria(idVetrina, file);
                            }
                        }
                        navigate("/completa");
                    } else {
                        M.toast({ html: 'Qualcosa è andato storto, riprovare!' });
                    }
                })
        });
    }

    const uploadPropic = (idTabella, propic) => {
        uploadFile(idTabella, propic, 'foto_profilo', 'utenti', 'foto_profilo');
    }

    const uploadImmagineGalleria = (idTabella, immagine) => {
        uploadFile(idTabella, immagine, 'galleria', 'gallerie', 'path_file');
    }

    const uploadCopertina = (idTabella, copertina) => {
        uploadFile(idTabella, copertina, 'copertina', 'vetrina', 'copertina');
    }

    const uploadFile = async (idTabella, file, dir, dbTable, dbField) => {
        await dbService.uploadFile(idTabella, file, dir, dbTable, dbField);
    }

    const geocodeAddress = anagrafica => {
        const promessa = new Promise(resolve => {
            fromAddress(
                anagrafica.indirizzo + ' ' + anagrafica.numeroCivico + ' ' +
                anagrafica.comune + ' ' +
                anagrafica.provincia + ' ' + anagrafica.cap + ' ' +
                'Italia'
            ).then(result => {
                resolve(result);
            })
        });
        return promessa;
    }

    const validate = () => {
        let resp;
        switch (current) {
            case 1:
                resp = anagraficaRef.current.validate();

                if (!resp) {
                    M.toast({ html: 'Completa correttamente i campi' });
                    return false;
                }

                forward();
                break;
            case 2:
                resp = orariRef.current.validate();

                if (!resp) {
                    M.toast({ html: 'Completa gli orari con tutte le informazioni' });
                    return false;
                }

                forward();
                break;
            case 3:
                resp = descrizioneRef.current.validate();

                if (!resp) {
                    M.toast({ html: 'Aggiungi una breve descrizione' });
                    return false;
                }

                forward();
                break;
            case 4:
                setLoading(() => true);
                registerCommerciante();
                break;
            default:
                M.toast({ html: 'Qualcosa non ha funzionato' });
                break;
        }
    }

    const backward = () => {
        if (current > 1) {
            setCurrent(prev => prev - 1);
        }
    }

    const forward = () => {
        if (current < tot) {
            setCurrent(prev => prev + 1);
        }
    }

    return (
        <div className="registrazione">
            <LoaderPercent percent={percent} />

            {loading && <Loader />}

            <div className="page-container">
                <div className={current === 1 ? 'page active' : 'page'} data-id="1">
                    <AnagraficaVetrina ref={anagraficaRef} />
                </div>

                <div className={current === 2 ? 'page active' : 'page'} data-id="2">
                    <OrarioVetrina ref={orariRef} />
                </div>

                <div className={current === 3 ? 'page active' : 'page'} data-id="3">
                    <DescrizioneVetrina ref={descrizioneRef} />
                </div>

                <div className={current === 4 ? 'page active': 'page'} data-id="4">
                    <ImmaginiVetrina ref={immaginiRef} />
                </div>

                <BottomBar forward={validate} backward={backward} tot={tot} current={current} />

            </div>

        </div>
    );
}

export default Vetrina;