import "../componentStyle/BottomBar.scoped.css";
import React from "react";

const BottomBar = ({forward, backward, current, tot}) => {
    return (
        <div className="bottom-bar">
            <div className={current > 1 ? 'btn btn-flat' : 'btn btn-flat opaque'}
                 onClick={backward}>Indietro
            </div>
            <div className="pagination">{current} / {tot}</div>
            <div className="btn" onClick={forward}>{tot === current ? 'Fine' : 'Avanti'}</div>
        </div>
    );
}

export default BottomBar;