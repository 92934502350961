import React, {useEffect, useRef, useState} from "react";
import "../pagesStyle/CaricaScontrino.scoped.css";
import CordovaModal from "../components/CordovaModal";
import Loader from "../components/Loader";
import {useNavigate} from "react-router-dom";
import ImageLoader from "../utils/ImageLoader";
import dbService from "../utils/DbService";
import { useSelector} from "react-redux";
import M from 'materialize-css';
import Config from "../config";

const CaricaScontrino = () => {
    const [loading, setLoading] = useState(true);
    const [listaConcorsi, setListaConcorsi] = useState([]);
    const [listaEsercenti, setListaEsercenti] = useState([]);
    const [hasScontrino, setHasScontrino] = useState(false);
    const [scontrino, setScontrino] = useState('');
    const [messaggioFineConcorso, setMessaggioFineConcorso] = useState('');
    const [concorso, setConcorso] = useState('');
    const [buoniTerminati, setBuoniTerminati] = useState(false);
    const [importo, setImporto] = useState('');
    const [numeroScontrino, setNumeroScontrino] = useState('');
    const [dataScontrino, setDataScontrino] = useState('');
    const [esercente, setEsercente] = useState('');
    const [hasConcorso, setHasConcorso] = useState(false);

    const token = useSelector(state => state.user.token),
        concorsoState = useSelector(state => state.user.concorso);

    const navigate = useNavigate();
    const uploadCordova = useRef(null);

    const handleImporto = e => {
        const valueWithoutComma = e.target.value.replace(/[^0-9]/g, '');
        const formattedValue = formatCurrency(valueWithoutComma);
        setImporto(() => formattedValue);
    }

    const formatCurrency = value => {
        const cleanedValue = value.replace(/[,\s]/g, '');

        // Controlla se il valore contiene solo zeri
        const isAllZeros = /^0+$/.test(cleanedValue);

        if (isAllZeros) {
            return '0,00';
        }

        // Converte il valore in un numero e divide per 100
        const numericValue = parseFloat(cleanedValue || 0) / 100;

        // Formatta il numero con due decimali
        return numericValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).replace(/,/g, "");
    }

    const handleDataScontrino = e => {
        setDataScontrino(() => e.target.value);
    }

    const handleNumeroScontrino = e => {
        setNumeroScontrino(() => e.target.value);
    }

    const handleEsercente = e => {
        setEsercente(() => e.target.value);
    }

    const fromCordova = b64 => {
        setHasScontrino(true);
        setScontrino(b64);
    }

    const generaGrattaVinci = async () => {
        if (!importo) {
            M.toast({html: 'Inserisci un importo del tuo scontrino'});
            return false;
        }
        if (!numeroScontrino) {
            M.toast({html: 'Inserisci il numero dello scontrino'});
            return false;
        }

        if (parseInt(importo) <= 0) {
            M.toast({html: 'Inserisci un importo valido del tuo scontrino'});
            return false;
        }

        if (!dataScontrino) {
            M.toast({html: 'Inserire una data dello scontrino valida'})
            return false
        }

        if (!esercente) {
            M.toast({html: 'Scegli un esercente tra i suggerimenti'});
            return false;
        }

        if (!scontrino) {
            M.toast({html: 'Carica la foto del tuo scontrino'});
            return false;
        }

        setLoading(true);
        const resp = await dbService.uploadScontrino(concorso, esercente, importo, numeroScontrino, dataScontrino, token, scontrino, 'scontrini');
        if (resp.status === 'OK') {
            const numeroGrattaVinci = resp.numeroGrattaVinci;
            navigate(`/haiottenuto/${numeroGrattaVinci}`);
        } else {
            setLoading(false);
            M.toast({html: 'Qualcosa è andato storto'});
            return false;
        }
    }

    const uploadScontrino = e => {
        const file = e.target.files[0];
        if (!file) {
            setHasScontrino(false);
            setScontrino('');
            return false;
        }

        setLoading(true);

        ImageLoader
            .resize(file, 560)
            .then(dataurl => {
                setHasScontrino(true);
                setScontrino(dataurl);
                setLoading(false);
            });
    }

    const loadEsercenti = async e => {
        listaConcorsi.forEach(conc => {
            if (conc.id === e.target.value) {
                setMessaggioFineConcorso(`entro le 23:59 del ${conc.dataFine}`);
            }
        });
        const esercenti = await dbService.getLocaliConcorso(e.target.value, token);
        setListaEsercenti(() => {
            esercenti.sort(function (a,b) {
                if (a.nomeAttivita.toLowerCase() < b.nomeAttivita.toLowerCase()) return -1;
                if (a.nomeAttivita.toLowerCase() > b.nomeAttivita.toLowerCase()) return 1;
                return 0;
            });
            if (esercenti[0]) {
                setEsercente(esercenti[0].id);
            }
            return esercenti;
        });
        await checkBuoni(e.target.value);
    }

    const checkBuoni = async idConcorso => {
        const buoniTerminati = await dbService.checkBuoniTerminati(idConcorso, token);
        setBuoniTerminati(!buoniTerminati);
    }

    const checkConcorsoAttivo = async concorso => {
        const checkConcorsoAttivo = await dbService.checkConcorso(concorso.id, token);
        if (checkConcorsoAttivo.check) {
            setListaConcorsi([concorso]);
            setConcorso(concorso.id);
            setHasConcorso(true);
        } else {
            setListaConcorsi([]);
            setConcorso('');
            setHasConcorso(false);
        }
        return checkConcorsoAttivo.check;
    }

    useEffect(() => {
        checkConcorsoAttivo(concorsoState).then(concorsoAttivo => {
            if (concorsoAttivo) {
                loadEsercenti({target: { value: concorsoState.id}}).then(() => setLoading(false));
            } else {
                setLoading(false);
            }
        });
    }, []);

    return (
        <div className="container relativo">
            {loading && <Loader fixed="yes" />}

            <div className="row">
                <div className={"indietro"} onClick={() => navigate(`/azioni`)}>
                    <img src={`${Config.subDirectory}/assets/indietro.png`} alt={"indietro"}/>
                </div>

                <h4 className="logo-blue">Carica Scontrino</h4>

                {!hasConcorso && <div className="col s12 no-concorsi">
                    <img src={`${Config.subDirectory}/assets/bazar.png`}  alt={"no concorsi"}/>
                    <p>Non ci sono concorsi attivi al momento</p>
                    <p>Seguici su facebook per scoprire i prossimi eventi!</p>
                </div>}

                {hasConcorso && <div>
                    <div className="webcam-view">
                        <input type="file" accept="image/*;capture=camera" onChange={uploadScontrino} />
                        {!hasScontrino && <div className="box-scontrino">
                            <img src={`${Config.subDirectory}/assets/_carica_scontrino.png`}  alt={"carica scontrino"}/>
                        </div>}

                        {hasScontrino && <img src={scontrino} alt={"scontrino"}/>}
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <p>Carica una foto del tuo scontrino</p>
                        <p style={{marginTop: '-17px', fontSize: '0.9rem'}}>{messaggioFineConcorso}</p>
                    </div>
                    {buoniTerminati && <p style={{color: 'red'}}>BUONI TERMINATI PER QUESTO CONCORSO!</p>}
                    <div className="col s12 input-styled">
                        <label htmlFor="concorso">A quale concorso partecipi?</label>
                        <select id="concorso" value={concorso} className="browser-default" onChange={() => loadEsercenti}>
                            {listaConcorsi.map(conc => (
                                <option key={conc.id} value={conc.id}>{conc.nome}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col s12 input-styled">
                        <label htmlFor="importo">Importo dello scontrino</label>
                        <input id="importo" type="number" className="validate browser-default" value={importo} onChange={handleImporto}/>
                    </div>

                    <div className="col s12 input-styled">
                        <label htmlFor="numero_scontrino">Numero dello scontrino</label>
                        <input id="numero_scontrino" type="text" className="validate browser-default" value={numeroScontrino} onChange={handleNumeroScontrino}/>
                    </div>

                    <div className="col s12 input-styled">
                        <label htmlFor="data_scontrino">Data dello scontrino</label>
                        <input id="data_scontrino" type="date" className="validate browser-default" value={dataScontrino} onChange={handleDataScontrino}/>
                    </div>


                    <div className="col s12 input-styled">
                        <label htmlFor="autoComplete">Esercente</label>
                        <select id="autoComplete" value={esercente} className="browser-default" onChange={handleEsercente}>
                            {listaEsercenti.map(es => (
                                <option key={es.id} value={es.id}>{es.nomeAttivita}</option>
                            ))}
                        </select>
                    </div>

                    <div className="btn btn-fullwidth" onClick={generaGrattaVinci}>Carica scontrino</div>
                </div>}
            </div>

            <CordovaModal ref={uploadCordova} upload={fromCordova} />
        </div>
    );
}

export default CaricaScontrino;
