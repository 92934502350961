import React, {useState} from "react";
import "../pagesStyle/Recupera.scoped.css";
import {useNavigate} from "react-router-dom";
import {removeRecuperoPassword} from "../reducers/accountReducers";
import {useDispatch} from "react-redux";
import dbService from "../utils/DbService";
import M from 'materialize-css';
import Config from "../config";

const Recupera = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleEmail = e => {
        setEmail(e.target.value);
    }

    const onRecover = () => {
        setLoading(true);
        dbService
            .resetPassword(email)
            .then(resp => {
                setLoading(false);
                if (resp.status === 'OK') {
                    M.toast({
                        html: 'Ti abbiamo inviato una mail per recuperare la password'
                    });
                    setTimeout(() => {
                        dispatch(removeRecuperoPassword(true));
                    }, 1000);
                } else {
                    M.toast({
                        html: 'Indirizzo email non presente e/o non corretto'
                    });
                }
            })
    }


    return (
        <div className="home">
            {loading && <div className="progress">
                <div className="indeterminate"></div>
            </div>}

            <div className="logo-container">
                <img alt="Ascom Buoni"
                     className="main-logo"
                     src={Config.logoHome}/>
            </div>

            <div className="container home-container">
                <div className="row">
                    <div className="col s12" style={{textAlign: 'center'}}>
                        <h6>Recupera password</h6>
                    </div>

                    <div className="input-field col s12">
                        <input id="email" type="email" className="validate" value={email} onChange={handleEmail}/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        {!loading &&
                            <button className="btn" type="submit" name="action" onClick={() => onRecover()}> Recupera
                                password </button>}
                        <p>Hai un account? <a onClick={() => navigate("/")} className="standard-link">Entra</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Recupera;