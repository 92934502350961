import React, {useState} from "react";
import "../pagesStyle/ModificaPassword.scoped.css";
import Loader from "../components/Loader";
import M from "materialize-css";
import {Navigate, useNavigate} from "react-router-dom";
import dbService from "../utils/DbService";
import {useSelector} from "react-redux";

const ModificaPassword = ({reset, tokenRecover}) => {
    const [loading, setLoading] = useState(false);
    const [corrente, setCorrente] = useState('');
    const [password, setPassword] = useState('');
    const [conferma, setConferma] = useState('');
    const [redirectReset, setRedirectReset] = useState(false);

    const navigate = useNavigate();
    let token = useSelector(state => state.user.token);
    if (reset) {
        token = tokenRecover;
    }

    const handleCorrente = e => {
        setCorrente(e.target.value);
    }

    const handlePassword = e => {
        setPassword(e.target.value);
    }

    const handleConferma = e => {
        setConferma(e.target.value);
    }

    const modificaDati = () => {
        if (password.length < 6) {
            M.toast({html: 'Inserisci una password di almeno 6 caratteri'});
            return false;
        }

        if (password !== conferma) {
            M.toast({html: 'Le due password devono coincidere'});
            return false;
        }

        setLoading(true);
        dbService
            .updatePassword(password, reset ? null : corrente, token)
            .then(resp => {
                setLoading(false);
                setPassword('');
                setCorrente('');
                setConferma('');
                if (resp.status === 'OK') {
                    M.toast({html: 'Password cambiata con successo'});
                    setTimeout(() => {
                        if (!reset) {
                            navigate("/profilo");
                        } else {
                            setRedirectReset(true);
                        }
                    }, 1000);
                } else {
                    M.toast({html: reset ? 'Qualcosa è andato storto' : 'Password corrente errata'});
                }
            })
    }

    return (
        <div className="container">
            {redirectReset && <Navigate to={"/"} replace />}
            <div className="row">
                {loading && <Loader />}

                <h4 className="logo-blue">Modifica password</h4>

                {!reset && <div className="col s12 input-styled">
                    <label htmlFor="corrente">Password Corrente</label>
                    <input id="corrente" type="password" className="validate browser-default" value={corrente} onChange={handleCorrente}/>
                </div>}
                <div className="col s12 input-styled">
                    <label htmlFor="password">Nuova Password</label>
                    <input id="password" type="password" className="validate browser-default" value={password} onChange={handlePassword}/>
                </div>
                <div className="col s12 input-styled">
                    <label htmlFor="conferma">Ripeti nuova password</label>
                    <input id="conferma" type="password" className="validate browser-default" value={conferma} onChange={handleConferma}/>
                </div>

                <button className="btn waves-effect waves-light" type="submit" name="action" onClick={modificaDati}> Modifica </button>
            </div>
        </div>
    );
}

export default ModificaPassword;