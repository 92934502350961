import React from "react";
import "../pagesStyle/HaiOttenuto.scoped.css";
import {useNavigate, useParams} from "react-router-dom";
import Config from "../config";
const HaiOttenuto = () => {

    const {grattaVinci} = useParams();
    const navigate = useNavigate();

    return (<div className="relativo" style={{textAlign: 'center'}}>
        <div className={"indietro"} onClick={() => navigate(`/carica-scontrino`)}>
            <img src={`${Config.subDirectory}/assets/delete.png`}  alt={"indietro"}/>
        </div>

        <h4 className="logo-blue">Complimenti!</h4>

        <h5 className="subhead">Hai ottenuto {grattaVinci} "Clicca e Vinci"</h5>

        <img src={`${Config.subDirectory}/assets/gratta_vinci.jpg`}  alt={'gratta vinci'}/>

        <p>I tuoi clicca e vinci sono stati aggiungi nella sezione principale</p>

        <div className={"btn btn-fullwidth"} onClick={() => navigate(`/gratta-vinci`)}>
            Gioca ora
        </div>

        <a href={"/carica-scontrino"} className={"standard-link"}>Carica un altro scontrino</a>
    </div>)
}

export default HaiOttenuto;
