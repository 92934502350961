import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "../pagesStyle/SingoloGrattaVinci.scoped.css";
import Loader from "../components/Loader";
import {useSelector} from "react-redux";
import dbService from "../utils/DbService";
import Config from "../config";

const SingoloGrattaVinci = () => {
    const [loading, setLoading] = useState(true);
    const [vinto, setVinto] = useState(false);
    const [immagineGratta, setImmagineGratta] = useState('');
    const [testoVittoria, setTestoVittoria] = useState('');
    const [caselle, setCaselle] = useState([]);
    const [continua, setContinua] = useState(false);
    const [vinti, setVinti] = useState('<div style="margin: 0 auto"><img src="https://www.ascombuoni.it/perso.jpg" style="width: 90%;" alt="immagine perso"></div>');
    const [nessunAltro, setNessunAltro] = useState(false);

    const perso = `${Config.subDirectory}/assets/perso.png`;

    const {idGratta} = useParams();
    const navigate = useNavigate();
    const token = useSelector(state => state.user.token);

    const gratta = key => {
        if (nessunAltro) {
            return false;
        }

        setCaselle(prevCaselle => {
            let tempCaselle ;
            if (prevCaselle[key].vinto) {
                tempCaselle = prevCaselle.map(casella => {
                    return {
                        ...casella,
                        grattato: true
                    };
                });
                setNessunAltro(true);
                setVinti(testoVittoria);
            }
            if (tempCaselle) {
                tempCaselle = tempCaselle.map((casella, index) => {
                    if (index === key) {
                        return {
                            ...casella,
                            grattato: true
                        };
                    } else {
                        return {...casella};
                    }
                })
            } else {
                tempCaselle = prevCaselle.map((casella, index) => {
                    if (index === key) {
                        return {
                            ...casella,
                            grattato: true
                        };
                    } else {
                        return {...casella};
                    }
                })
            }
            setGrattato(tempCaselle);
            return tempCaselle;
        });
    }

    const setGrattato = tempCaselle => {
        let daGrattare = true,
            giaVinto = false;

        tempCaselle.forEach(casella => {
            if (!casella.grattato) {
                daGrattare = false;
            }
            if (casella.grattato && casella.vinto) {
                giaVinto = true;
                setVinto(true);
            }
        });

        if (daGrattare || giaVinto) {
            dbService
                .setGrattato(token, idGratta)
                .then(() => {
                    setContinua(true);
                });
        }
    }

    useEffect(() => {
        const tempCaselle = [];
        for (let i = 0; i < 9; i++) {
            tempCaselle.push({
                grattato: false,
                vinto: false
            });
        }
        dbService
            .getSingoloGrattaVinci(token, idGratta)
            .then(resp => {
                if (resp.grattato === "1") {
                    navigate('/gratta-vinci');
                }
                if (resp.vincente === "1") {
                    const qualeVince = parseInt(resp.quale_vince);
                    tempCaselle[qualeVince - 1] = {
                        ...tempCaselle[qualeVince - 1],
                        grattato: false,
                        vinto: true
                    };
                    dbService
                        .getDatiGratta(token, idGratta)
                        .then(resp2 => {
                            if (resp2.img_gratta && resp2.testo_vinto_gratta) {
                                setTestoVittoria(resp2.testo_vinto_gratta);
                                setImmagineGratta(Config.dbBaseUrl + resp2.img_gratta);
                                setCaselle(tempCaselle);
                                setLoading(false);
                            }
                        });
                } else {
                    dbService
                        .getDatiGrattaPerdente(token, idGratta)
                        .then(resp2 => {
                            if (resp2.img_perso) {
                                setVinti(`<div style="margin: 0 auto"><img src="${Config.dbBaseUrl + resp2.img_perso}" style="width: 90%;" alt="immagine perso"></div>`)
                                setCaselle(tempCaselle);
                                setLoading(false);
                            }
                        });
                }
            })
    }, []);

    return (
        <div className="padded">
            {loading && <Loader />}
            <div className="gratta-vinci">
                <div className="gratta-card">
                    <div className="gratta-top">
                        <span>Clicca e vinci</span>
                    </div>

                    {vinto && <div className="gratta-content buono-sponsor">
                        <img src={immagineGratta} className="buono-vinto-2" alt={"buono vinto"}/>
                    </div>}

                    {!vinto && <div className="gratta-content">
                        {caselle.map((casella, key) => (
                            <div className={casella.grattato ? 'animation-container anima' : 'animation-container'}
                            onClick={() => gratta(key)}
                            key={key}>
                                <img src={`${Config.subDirectory}/assets/dagrattare.png`} className="fore" alt={"da grattare"} />
                                <img src={casella.vinto ? immagineGratta : perso} alt={"grattato"} />
                            </div>
                            ))}
                    </div>}
                </div>
            </div>
            {!continua &&
                <p>Tocca ogni casella per scoprire<br/>se hai vinto!</p>
            }
            {continua &&
                <div className={"vinti-grattati"} style={{textAlign:'center'}}>
                    <span dangerouslySetInnerHTML={{__html: vinti}}/>
                </div>
            }
            {continua && <div style={{textAlign: 'center'}}>
                <div className={"btn btn-fullwidth"} onClick={() => navigate(`/gratta-vinci`)} >Continua a giocare</div>
            </div>}
</div>
    );
}

export default SingoloGrattaVinci;