import React, {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import dbService from "../utils/DbService";
import "../pagesStyle/Home.scoped.css";
import ModificaPassword from "./ModificaPassword";

const RecuperaPassword = () => {
    const [utente, setUtente] = useState({});
    const [invalid, setInvalid] = useState(false);

    const {token} = useParams();

    useEffect(() => {
        dbService
            .getUtenteByToken(token)
            .then(resp => {
                if (resp.status === 'OK') {
                    setUtente(resp.utente);
                } else {
                    setInvalid(true);
                }
            })
    }, []);

    return (
        <>
            <ModificaPassword reset={!!utente} tokenRecover={token} />
            {invalid && <Navigate to={"/"} replace />}
        </>
    )
}

export default RecuperaPassword;