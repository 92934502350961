import React, {useEffect, useRef, useState} from "react";
import "../pagesStyle/Portafogli.scoped.css";
import Loader from "../components/Loader";
import M from "materialize-css";
import {useSelector} from "react-redux";
import dbService from "../utils/DbService";
import Config from "../config";
import VistaNuovo from "../components/VistaNuovo";
import QRCode from "qrcode";

const Portafogli = () => {
    const [loading, setLoading] = useState(true);
    const [acquisizioneBuono, setAcquisizioneBuono] = useState(false);
    const [totaleDaUsare, setTotaleDaUsare] = useState(0);
    const [totaleUsato, setTotaleUsato] = useState(0);
    const [totaleAcquisiti, setTotaleAcquisiti] = useState(0);
    const [buoniNuovi, setBuoniNuovi] = useState([]);
    const [buoniVecchi, setBuoniVecchi] = useState([]);
    const [buoniAcquisiti, setBuoniAcquisiti] = useState([]);
    const [buonoOpened, setBuono] = useState({});
    const [qrData, setQrData] = useState('');
    const portafogliRef = useRef(null);
    const utente = useSelector(state => state.user);

    useEffect(() => {
        const tabs = portafogliRef.current.querySelectorAll(".tabs");
        M.Tabs.init(tabs, {});

    })

    useEffect(() => {
        setAcquisizioneBuono(Config.acquisizioneBuono && utente.concorso.parametri.acquisizione_buono === "1");
        dbService
            .getBuoni(utente.token)
            .then(resp => {
                const buoni = manageBuoni(resp);
                setBuoni(buoni);

                if (utente.tipologia === 'ESERCENTE') {
                    dbService
                        .getBuoniAcquisiti(utente.token)
                        .then(buoni => {
                            manageAcquisiti(buoni);
                            setLoading(false);
                        })
                } else {
                    setLoading(false);
                }
            })

    }, []);

    const getModal = () => {
        const modalElement = portafogliRef.current.querySelector('#singolo-qr');
        M.Modal.init(modalElement, {
            dismissible: true
        });
        return M.Modal.getInstance(modalElement);
    }

    const openBuono = buonoPassed => {
        console.log(buonoPassed)
        if (!buonoPassed.abilitato) {
            return false;
        }
        console.log('inizio ad aprire')
        QRCode.toDataURL(buonoPassed.codice, {color: {dark: '#1BB5C1', light: '#FFFFFF'}}, (err, url) => {
            console.log('faccio cose')
            setBuono(buonoPassed);
            setQrData(url);
            getModal().open();
        });
    }

    const manageAcquisiti = buoni => {
        const acquisiti = [];
        let totAcquisiti = 0;

        buoni.forEach(buono => {
            totAcquisiti += parseFloat(buono.importo);
            buono.dataFormat = new Date(buono.data_caricamento).toLocaleDateString();
            acquisiti.push(buono);
        })
        setBuoniAcquisiti(acquisiti);
        totAcquisiti = totAcquisiti.toFixed(2);
        setTotaleAcquisiti(totAcquisiti);
    }

    const setBuoni = buoni => {
        const nuovi = [],
            vecchi = [];
        let totVecchi = 0,
            totNuovi = 0;

        buoni.forEach(buono => {
            if (buono.utilizzato === "0") {
                nuovi.push(buono);
                totNuovi += parseFloat(buono.importo);
            } else {
                vecchi.push(buono);
                totVecchi += parseFloat(buono.importo);
            }
        });
        setBuoniNuovi(nuovi);
        setBuoniVecchi(vecchi);
        totNuovi = totNuovi.toFixed(2);
        totVecchi = totVecchi.toFixed(2);
        setTotaleDaUsare(totNuovi);
        setTotaleUsato(totVecchi);
    }

    const manageBuoni = buoni => {
        const returnBuoni = [];
        buoni.forEach(buono => {
            const datiBuono = {
                ...buono
            };
            if (!buono.abilitato && buono.controllato) {
                datiBuono.disabilitato = true;
            } else {
                datiBuono.disabilitato = false;
            }
            if (!datiBuono.hasOwnProperty('controllato')) {
                datiBuono.abilitato = false;
                datiBuono.disabilitato = false;
            }
            datiBuono.dataFormat = new Date(datiBuono.data_caricamento).toLocaleDateString();
            returnBuoni.push(datiBuono);
        })
        return returnBuoni;
    }


    return (<div className="page-completa relativo" ref={portafogliRef}>
        {loading && <Loader fixed="yes"/>}

        <ul className="tabs">
            <li className="tab col s6"><a href="#nuovi" className="active">{totaleDaUsare} &euro;<br/>Buoni da<br/>spendere</a>
            </li>
            <li className="tab col s6"><a href="#vecchi">{totaleUsato} &euro;<br/>Buoni<br/>Spesi</a></li>
            {utente.tipologia === 'ESERCENTE' && acquisizioneBuono && <li className="tab col s6">
                <a href="#acquisiti">{totaleAcquisiti} &euro;<br/>Buoni<br/>Acquisiti</a>
            </li>}
        </ul>

        <div id="nuovi" className="gratta-tab">
            <div className="flexer-grattavinci">
                {buoniNuovi.length === 0 && <div className="no-buoni">
                    Non vi sono buoni da spendere
                </div>}
                {buoniNuovi.map(buono => <>
                    {!buono.disabilitato && <div className={"card-buono"}
                                                 onClick={() => openBuono(buono)}
                                                 key={buono.id}>
                        <div style={{textAlign: 'center'}}>
                            <div className={"top-card"}>
                                {buono.dataFormat}
                            </div>
                            {buono.tipo === 'buono' && <>
                                <div className={"middle-card"}>
                                    <b>{buono.importo}</b>.00 &euro;
                                </div>
                                <div className={"bottom-card"}>
                                    {buono.abilitato && <div
                                        className="vcentering">{buono.scaduto ? 'BUONO SCADUTO' : buono.nomeConcorso}</div>}
                                    {!buono.abilitato &&
                                        <div className="vcentering">Buono in attesa di approvazione</div>}
                                </div>
                            </>}
                            {buono.tipo !== 'buono' &&
                                <img className={buono.tipo} src={Config.dbBaseUrl + buono.immagine}
                                     alt={"immagine buono"}/>}
                        </div>
                    </div>}
                </>)}

            </div>
        </div>

        <div id="vecchi" className="gratta-tab">
            <div className="flexer-grattavinci">
                {buoniVecchi.length === 0 && <div className="no-buoni">
                    Non vi sono buoni spesi
                </div>}
                {buoniVecchi.map(buono => <>
                    <div className={"card-buono"} key={buono.codice}>
                        <div className="top-card">
                            {buono.dataFormat}
                        </div>
                        <div className="middle-card barrata">
                            <b>{buono.importo}</b>.00 &euro;
                        </div>
                        <div className="bottom-card">
                            <div className="vcentering">{buono.scaduto ? 'BUONO SCADUTO' : buono.nomeConcorso}</div>
                        </div>
                    </div>
                </>)}
            </div>
        </div>
        {utente.tipologia === 'ESERCENTE' && acquisizioneBuono && <div className={"gratta-tab"} id={"acquisiti"}>
            <div className={"flexer-grattavinci"}>
                {buoniAcquisiti.length === 0 && <div className={"no-buoni"}>
                    Non vi sono buoni acquisiti
                </div>}
                {buoniAcquisiti.map(buono => <>
                    <div className={"card-buono"} key={buono.codice}>
                        <div className={"top-card"}>
                            {buono.dataFormat}
                        </div>
                        <div className="middle-card barrata">
                            <b>{buono.importo}</b>.00 &euro;
                        </div>
                        <div className="bottom-card">
                            <div className="vcentering">
                                <span className="nome-concorso-acquisito">{buono.nomeConcorso}</span> <br/>
                                <span
                                    className="nome-utilizzatore">({buono.utente.cognome} {buono.utente.nome})</span>
                            </div>
                        </div>
                    </div>
                </>)}
            </div>
        </div>}
        <div id="singolo-qr" className="modal">
            <div className="modal-content">
                <VistaNuovo buono={buonoOpened} qrData={qrData}/>
            </div>
        </div>


    </div>);
}

export default Portafogli;