import React, {useState} from "react";
import "../componentStyle/SearchBar.scoped.css";
import Config from "../config";

const SearchBar = ({ricerca}) => {
    const [testo, setTesto] = useState('');
    const [searched, setSearched] = useState(false);

    const onRicerca = () => {
        setSearched(() => testo !== '');
        ricerca(testo.toLowerCase());
    }

    const reset = () => {
        setTesto(() => '');
        setSearched(() => false);
        ricerca('');
    }

    const handleTesto = e => {
        setTesto(() => e.target.value);
    }

    const filterIfEnter = () => {
        onRicerca();
    }

    return (<div className="search-bar">
        <input type="text" className="browser-default" value={testo} onKeyUp={filterIfEnter}
               onChange={handleTesto}/>
        {!searched && <img src={`${Config.subDirectory}/assets/lente.png`} onClick={onRicerca} alt={"cerca"}/>}
        {searched && <img src={`${Config.subDirectory}/assets/delete.png`} onClick={reset} alt={"annulla"}/>}
    </div>);
}

export default SearchBar;