import React, {useEffect, useRef, useState} from "react";
import "../pagesStyle/CaricaBuono.scoped.css";
import Loader from "../components/Loader";
import BarcodeScanner from "../components/BarcodeScanner";
import {useNavigate} from "react-router-dom";
import dbService from "../utils/DbService";
import {useSelector} from "react-redux";
import M from "materialize-css";
import Config from "../config";

const CaricaBuono = () => {
    const [loading, setLoading] = useState(false);
    const [qr, setQr] = useState('');
    const [deviceId, setDeviceId] = useState("normale");
    const [listDevice, setListDevice] = useState([]);

    const navigate = useNavigate();
    const barcodeRef = useRef(null);

    const token = useSelector(state => state.user.token);

    const indietro = () => {
        barcodeRef.current.stopCamera();
        navigate("/azioni");
    }

    const handleQr = e => {
        setQr(e.target.value);
    }

    const checkBuono = () => {
        setLoading(true);
        dbService
            .checkBuono(token, qr.toUpperCase())
            .then((resp) => {
                setLoading(false);
                setQr('');
                M.toast({html: resp.message});
            });
    }

    const leggiCodice = testo => {
        if (testo) {
            setQr(testo);
        }
    }

    /*const handleDevice = e => {
        setDeviceId(e.target.value);
    }*/

    /*useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(availableDevices => {
            const availableVideoDevices = availableDevices.filter(device => device);
            setListDevice(() => availableVideoDevices.map(device => {
                return {deviceId: device.deviceId, label: device.label};
            }));
        });
    }, []);*/


    return (
        <div className="container relativo">
            {loading && <Loader />}

            <div className="scroller" style={{textAlign: 'center'}}>
                <div className="indietro" onClick={() => indietro()}>
                    <img src={`${Config.subDirectory}/assets/indietro.png`} alt={"indietro"}/>
                </div>

                <h4 className="logo-blue">Acquisisci Buono</h4>

                <div className="webcam-view">
                    <BarcodeScanner sendResult={leggiCodice} ref={barcodeRef} deviceId={deviceId}/>
                </div>

                {/*<div className="col s12 input-styled label-centrata">
                    <label htmlFor="selectDevice">Seleziona la videocamera</label>
                    <select className={"browser-default"} onChange={handleDevice} value={deviceId} id={"selectDevice"}>
                        <option value={""}>Seleziona</option>
                        {listDevice.map(device => <option key={device.deviceId}
                                                          value={device.deviceId}>{device.label}</option>)}
                    </select>
                </div>*/}

                <p>Scansiona il codice dall'app del cliente</p>

                <div className="col s12 input-styled label-centrata">
                    <label htmlFor="nome">Inserisci il codice manualmente</label>
                    <input id="nome" type="text" className="validate browser-default" value={qr} onChange={handleQr}/>
                </div>

                <div className="btn btn-fullwidth" onClick={() => checkBuono()}>Riscatta codice</div>
            </div>
        </div>
    );
}

export default CaricaBuono;