const config = {
    googleMapsApiKey: 'AIzaSyDOG24cR2DMj6KFrSTgIuYj5Lk0jkLHysA',
    mapCenter: {
        lat: 45.324509, lng: 8.420160
    },
    nomeApp: 'Clicca & Vinci',
    idApplicazione: "1",
    geoCodeBaseUrl: "https://nominatim.openstreetmap.org/search",
    enableGeocodeLatLong: false,
    dbBaseUrl: 'https://zprotocol.cloud/concorsi-backend/',
    singoloConcorso: true,
    caricaCartoncino: false,
    abilitaMappa: false,
    idSingoloConcorso: "3",
    caricaScontrino: true,
    cliccaVinci: true,
    acquisizioneBuono: true,
    useGalleria: false,
    logoHome: 'https://zprotocol.cloud/concorsi-backend/images/clicca_vinci.png',
    subDirectory: '',
    mapStyles: [{
        "featureType": "poi", "elementType": "labels.text", "stylers": [{
            "visibility": "off"
        }]
    }, {
        "featureType": "poi.business", "stylers": [{
            "visibility": "off"
        }]
    }, {
        "featureType": "road", "elementType": "labels.icon", "stylers": [{
            "visibility": "off"
        }]
    }, {
        "featureType": "transit", "stylers": [{
            "visibility": "off"
        }]
    }]
};
export default config;
