import React, {useEffect, useState} from "react";
import "../componentStyle/VistaNuovo.scoped.css";
import Loader from "./Loader";
import dbService from "../utils/DbService";
import {useSelector} from "react-redux";
import QRCode from "qrcode";
import Config from "../config";

const VistaNuovo = ({buono, qrData}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (qrData) {
            setLoading(false)
        }
    }, [qrData]);

    return (
        <div className="altezza-buono">
            {loading && <Loader/>}

            {buono && <div style={{textAlign: 'center'}}>
                <span dangerouslySetInnerHTML={{__html: buono.testo_header}}/>

                <div className="dot-divider"></div>

                {buono.immagine && <span>
              <img src={Config.dbBaseUrl + buono.immagine} className="buono-vinto-2" alt={"buono vinto"}/>
            </span>}

                {buono.hasQR === "1" && !buono.scaduto &&
                    <span>
              <h5>Codice: {buono.codice}</h5>
              <img src={qrData} id="qr-code" alt={"qr code"}/>
            </span>}

                {buono.scaduto && <span>
              <h5 className="concorso-scaduto">Buono scaduto</h5>
            </span>}

                <div className="dot-divider"></div>

                <div className="sottotitolo-esercente">Scadenza: {new Date(buono.scadenzaAcquisizione).toLocaleDateString()}</div>

                {!buono.scaduto && <div className="titolo-esercente" dangerouslySetInnerHTML={{__html: buono.testo_vinto}}/>}

            </div>}
        </div>
    );
}

export default VistaNuovo;