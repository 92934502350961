import {configureStore} from '@reduxjs/toolkit';
import {createTransform, persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import {decryptData, encryptData} from "./utils/encryption";

const encryptionTransform = createTransform((inboundState, key) => encryptData(inboundState, 'ascom-root'), (outboundState, key) => decryptData(outboundState, 'ascom-root'));

const persistConfig = {
    key: 'ascom-root', storage: storage, transforms: [encryptionTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
});

export const persistor = persistStore(store);